import React from 'react';
import PropTypes from 'prop-types';
import { stadiumType } from '@helpers/types';
// npm imports
// mui imports
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Skeleton from '@mui/material/Skeleton';
import LocationOnIcon from '@mui/icons-material/LocationOn';
// local imports
import Map from '@components/shared/Map';

export default function StadiumMap({ isLoading, stadium }) {
  return (
    <Card>
      <Map stadiums={[stadium]} type="single" />
      <CardHeader
        avatar={
          isLoading ? (
            <Skeleton
              animation="wave"
              variant="circular"
              width={24}
              height={24}
            />
          ) : (
            <LocationOnIcon />
          )
        }
        title={
          isLoading ? (
            <Skeleton animation="wave" width={105} />
          ) : (
            stadium?.location?.address
          )
        }
        subheader={
          isLoading ? (
            <Skeleton animation="wave" width={145} />
          ) : (
            `${stadium?.location?.city}, ${stadium?.location?.region} ${stadium?.location?.postal}`
          )
        }
      />
    </Card>
  );
}

StadiumMap.defaultProps = {
  isLoading: false,
};

StadiumMap.propTypes = {
  isLoading: PropTypes.bool,
  stadium: stadiumType.isRequired,
};
