import { useState, useEffect } from 'react';
// npm imports
import wiki from 'wikijs';

/**
 * @name useWikipediaMainImage
 * @description retrieves main image from wikipedia link
 * @param wikipediaLink full url of wikipedia page to source link from
 * @returns {string} url of main wikipedia image
 */
function useWikipediaMainImage(wikipediaLink) {
  const [wikipediaMainImage, setWikipediaMainImage] = useState(null);

  useEffect(() => {
    if (wikipediaLink) {
      const pageTitle = wikipediaLink.replace(
        'https://en.wikipedia.org/wiki/',
        ''
      );

      if (pageTitle) {
        wiki({ apiUrl: 'https://en.wikipedia.org/w/api.php' })
          .page(pageTitle)
          .then(async (page) => {
            const wikiImage = await page.mainImage();

            setWikipediaMainImage(wikiImage);
          })
          .catch((e) => {
            // TODO( error-handling): add better error handling, possibly including a logging service
            console.log('There was an error loading your image.', e);
          });
      }
    }
  }, [wikipediaLink]);

  return wikipediaMainImage;
}

export default useWikipediaMainImage;
