import callAPI from './callAPI';

/**
 * @name addBucketlist
 * @description add stadium to bucketlist of single user
 * @param {string} accountId ID of the account
 * @param {string} venueId ID of the venue
 * @param {string} isOnBucketlist value to set bucketlist status to
 * @returns {object} response object
 */
export const addBucketlist = ({ accountId, venueId, isOnBucketlist }) => {
  return callAPI({
    url: '/accounts/bucketlists/', // TODO: remove trailing slash once that's figure out
    method: 'post',
    data: {
      accountId,
      venueId,
      isOnBucketlist,
    },
  });
};
