import React, { useEffect, useState } from 'react';
// npm imports
import { formatInTimeZone } from 'date-fns-tz';
import { useParams } from 'react-router-dom';
// mui imports

import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
// import Item from '@mui/material/Item';
import LinearProgress from '@mui/material/LinearProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// local imports
import { jsifyDate } from '@helpers/functions/jsifyDate';
import NotFound from '@pages/NotFound';
import { useAuth } from '@providers/AuthProvider';
import { Flags } from '@providers/FlagsProvider';
import { getProfile } from 'client/api';

import Link from '@components/shared/Link';

import { useLocation, useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  stadiumLocation: {
    textTransform: 'uppercase',
  },
}));

export default function Profile() {
  const { account } = useAuth();
  // const { profileSlug } = useParams();
  const classes = useStyles();
  // const isMobile = useMediaQuery((theme) => theme.breakpoints?.down('md'));
  const navigate = useNavigate();

  // component state
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [profile, setProfile] = useState(null);

  // convert date to JS version - TODO: convert date helper
  const memberDate = jsifyDate(profile?.joined);

  useEffect(() => {
    if (account) {
      getProfile({
        accountId: account?.id,
      })
        .then((response) => {
          setProfile(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setError(error);
          setIsLoading(false);
        });
    }
  }, [account]);

  if (error) {
    // TODO: better not found message
    return (
      <NotFound title="Not Found" subtitle="Profile could not be found." />
    );
  }

  const currentStadiums = {
    mlb: 30,
    nba: 29,
    nfl: 30,
    nhl: 32,
  };

  return (
    <Container max="lg" disableGutters>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography component="h1" variant="h3" align="left">
            {isLoading ? (
              <Skeleton animation="wave" width="25%" />
            ) : (
              `${profile?.firstName} ${profile?.lastName}`
            )}
          </Typography>
          <Typography
            variant="overline"
            align="left"
            className={classes.stadiumLocation}
          >
            {isLoading ? (
              <Skeleton animation="wave" width="33%" />
            ) : (
              `Member since ${formatInTimeZone(
                memberDate,
                'Etc/UTC',
                'MMMM yyyy'
              )}`
            )}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {['mlb', 'nba', 'nfl', 'nhl'].map((leagueFilter) => {
              const currentVisitCount =
                profile?.visits[leagueFilter].currentStadiumVisitsCount;
              return (
                <Grid key={leagueFilter} item xs={12} md={3}>
                  <Card variant="outlined">
                    <span
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        paddingTop: '16px',
                      }}
                    >
                      {/* <CardMedia
                  alt={`${leagueFilter.toUpperCase()} logo`}
                  component="img"
                  image={`https://i.logocdn.com/${leagueFilter}/current/${leagueFilter}.svg`}
                  sx={{
                    height: '100%',
                    width: '100%',
                    margin: 'auto',
                  }}
                /> */}
                      <img
                        src={`https://i.logocdn.com/${leagueFilter}/current/${leagueFilter}.svg`}
                        alt="MLB"
                        style={{ width: '150px', height: '150px' }}
                      ></img>
                    </span>
                    <CardContent>
                      {/* <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    {leagueFilter.toUpperCase()}
                  </Typography>
                  <Typography variant="h5" component="div">
                    bdogs
                  </Typography> */}
                      <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        {isLoading ? (
                          <>
                            <Skeleton animation="wave" width="100%" />
                            <Skeleton animation="wave" width="33%" />
                          </>
                        ) : (
                          <Typography sx={{ mb: 1.5 }} color="text.secondary">
                            You've visited <strong>{currentVisitCount}</strong>{' '}
                            of the {currentStadiums[leagueFilter]} current{' '}
                            {leagueFilter.toUpperCase()} stadiums
                          </Typography>
                        )}
                      </Typography>
                      <Divider variant="middle" />
                      <LinearProgress
                        variant="determinate"
                        value={
                          (currentVisitCount / currentStadiums[leagueFilter]) *
                          100
                        }
                      />
                    </CardContent>
                    <CardActions>
                      <Button
                        size="small"
                        onClick={() => navigate(`/stadiums/${leagueFilter}`)}
                      >
                        Go to {leagueFilter.toUpperCase()} Stadiums
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
