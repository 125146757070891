import React from 'react';
import PropTypes from 'prop-types';
// npm imports
import { formatInTimeZone } from 'date-fns-tz';
// mui imports
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Skeleton from '@mui/material/Skeleton';
import BuildIcon from '@mui/icons-material/Build';

const useStyles = makeStyles((theme) => ({
  // TODO: add cardHeader style to shared style file
  cardHeader: {
    background: theme.palette.secondary.main,
  },
}));

// TODO: deal with timezones
export default function StadiumConstruction({ isLoading, construction }) {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader
        avatar={
          isLoading ? (
            <Skeleton
              animation="wave"
              variant="circular"
              width={24}
              height={24}
            />
          ) : (
            <BuildIcon />
          )
        }
        className={classes.cardHeader}
        title={
          isLoading ? (
            <Skeleton animation="wave" width={110} />
          ) : (
            'Construction Info'
          )
        }
      />
      <CardContent>
        <List
          aria-labelledby="stadium-construction-info-subheader"
          subheader={
            <ListSubheader
              id="stadium-construction-info-subheader"
              disableGutters
            >
              {isLoading ? (
                <Skeleton animation="wave" width={85} />
              ) : (
                'Construction dates'
              )}
            </ListSubheader>
          }
        >
          {['Planned'].includes(construction.status) && (
            <ListItem>
              <ListItemText
                primary={
                  isLoading ? (
                    <Skeleton animation="wave" width={115} />
                  ) : (
                    'Future construction'
                  )
                }
                secondary={
                  isLoading ? (
                    <Skeleton animation="wave" width={145} />
                  ) : (
                    'This stadium is still in the planning phase'
                  )
                }
              />
            </ListItem>
          )}
          {[
            'Under construction',
            'Open',
            'Closed',
            'Demolished',
            'Repurposed',
          ].includes(construction.status) && (
            <ListItem>
              <ListItemText
                primary={
                  isLoading ? (
                    <Skeleton animation="wave" width={115} />
                  ) : (
                    'Groundbreaking'
                  )
                }
                secondary={
                  isLoading ? (
                    <Skeleton animation="wave" width={125} />
                  ) : (
                    (construction?.groundbreaking &&
                      formatInTimeZone(
                        new Date(construction?.groundbreaking),
                        'Etc/UTC',
                        construction?.precision?.groundbreaking === 'day'
                          ? 'MMMM d, yyyy'
                          : construction?.precision?.groundbreaking === 'month'
                          ? 'MMMM yyyy'
                          : 'yyyy'
                      )) ||
                    'Date unknown'
                  )
                }
              />
            </ListItem>
          )}
          {['Open', 'Closed', 'Demolished', 'Repurposed'].includes(
            construction.status
          ) && (
            <ListItem>
              <ListItemText
                primary={
                  isLoading ? (
                    <Skeleton animation="wave" width={60} />
                  ) : (
                    'Opened'
                  )
                }
                secondary={
                  isLoading ? (
                    <Skeleton animation="wave" width={125} />
                  ) : (
                    (construction?.opened &&
                      formatInTimeZone(
                        new Date(construction?.opened),
                        'Etc/UTC',
                        construction?.precision?.opened === 'day'
                          ? 'MMMM d, yyyy'
                          : construction?.precision?.opened === 'month'
                          ? 'MMMM yyyy'
                          : 'yyyy'
                      )) ||
                    'Date unknown'
                  )
                }
              />
            </ListItem>
          )}
          {['Closed', 'Demolished', 'Repurposed'].includes(
            construction.status
          ) && (
            <ListItem>
              <ListItemText
                primary={
                  isLoading ? (
                    <Skeleton animation="wave" width={55} />
                  ) : (
                    'Closed'
                  )
                }
                secondary={
                  isLoading ? (
                    <Skeleton animation="wave" width={125} />
                  ) : (
                    (construction?.closed &&
                      formatInTimeZone(
                        new Date(construction?.closed),
                        'Etc/UTC',
                        construction?.precision?.closed === 'day'
                          ? 'MMMM d, yyyy'
                          : construction?.precision?.closed === 'month'
                          ? 'MMMM yyyy'
                          : 'yyyy'
                      )) ||
                    'Date unknown'
                  )
                }
              />
            </ListItem>
          )}
          {['Demolished'].includes(construction.status) && (
            <ListItem>
              <ListItemText
                primary={
                  isLoading ? (
                    <Skeleton animation="wave" width={85} />
                  ) : (
                    'Demolished'
                  )
                }
                secondary={
                  isLoading ? (
                    <Skeleton animation="wave" width={125} />
                  ) : (
                    (construction?.demolished &&
                      formatInTimeZone(
                        new Date(construction?.demolished),
                        'Etc/UTC',
                        construction?.precision?.demolished === 'day'
                          ? 'MMMM d, yyyy'
                          : construction?.precision?.demolished === 'month'
                          ? 'MMMM yyyy'
                          : 'yyyy'
                      )) ||
                    'Date unknown'
                  )
                }
              />
            </ListItem>
          )}
        </List>
        {['Repurposed'].includes(construction.status) && (
          <List
            aria-labelledby="stadium-other-info-subheader"
            subheader={
              <ListSubheader id="stadium-other-info-subheader" disableGutters>
                {isLoading ? (
                  <Skeleton animation="wave" width={85} />
                ) : (
                  'Other info'
                )}
              </ListSubheader>
            }
          >
            <ListItem>
              <ListItemText
                primary={
                  isLoading ? (
                    <Skeleton animation="wave" width={85} />
                  ) : (
                    'Repurposed'
                  )
                }
                secondary={
                  isLoading ? (
                    <Skeleton animation="wave" width="100%" />
                  ) : (
                    'This stadium has been repurposed from its original construction, and while still open in some capacity, may no longer be a sports stadium.'
                  )
                }
              />
            </ListItem>
          </List>
        )}
      </CardContent>
    </Card>
  );
}

StadiumConstruction.defaultProps = {
  isLoading: false,
  construction: {
    closed: null,
    demolished: null,
    groundbreaking: null,
    opened: null,
    status: 'Open',
  },
};

StadiumConstruction.propTypes = {
  construction: PropTypes.shape({
    closed: PropTypes.string,
    demolished: PropTypes.string,
    groundbreaking: PropTypes.string,
    opened: PropTypes.string,
    precision: PropTypes.shape({
      closed: PropTypes.string,
      demolished: PropTypes.string,
      groundbreaking: PropTypes.string,
      opened: PropTypes.string,
    }),
    status: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
};
