/**
 * @file contains helper functions used throughout the app
 */

/**
 * @name jsifyDate
 * @description converts database (MySQL / PHP) dates into a javascript version for parsing
 * @param {string} date date too convert
 * @returns {object} javascript Date object
 */
export function jsifyDate(date) {
  return new Date(Date.parse(date?.replace(/[-]/g, '/')));
}
