import React from 'react';
import PropTypes from 'prop-types';
import { franchiseType } from '@helpers/types';
// mui imports
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Skeleton from '@mui/material/Skeleton';
import HomeIcon from '@mui/icons-material/Home';
// local imports
import TenantsList from '@components/Tenants/TenantsList';

const useStyles = makeStyles((theme) => ({
  // TODO: add cardHeader style to shared style file
  cardHeader: {
    background: theme.palette.secondary.main,
  },
}));

export default function StadiumTenants({ isLoading, tenants }) {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader
        avatar={
          isLoading ? (
            <Skeleton
              animation="wave"
              variant="circular"
              width={24}
              height={24}
            />
          ) : (
            <HomeIcon />
          )
        }
        className={classes.cardHeader}
        title={isLoading ? <Skeleton animation="wave" width={55} /> : 'Tenants'}
      />
      <CardContent>
        <TenantsList isLoading={isLoading} tenants={tenants} />
      </CardContent>
    </Card>
  );
}

StadiumTenants.defaultProps = {
  isLoading: false,
  tenants: [{ status: 'current' }, { status: 'current' }], // provide 2 blank objects to display Skeleton during loading
};

StadiumTenants.propTypes = {
  isLoading: PropTypes.bool,
  tenants: PropTypes.arrayOf(franchiseType), // TODO: update this to be franchise, or update other to be tenants
};
