import React from 'react';
import PropTypes from 'prop-types';
// npm imports
import { Link as RouterLink } from 'react-router-dom';
// mui imports
import MuiLink from '@mui/material/Link';

export default function Link({ children, external, state, to, variant }) {
  if (external) {
    return (
      <MuiLink href={to} target="blank" variant={variant} underline="hover">
        {children}
      </MuiLink>
    );
  }

  return (
    <MuiLink
      component={RouterLink}
      state={state}
      to={to}
      variant={variant}
      underline="hover"
    >
      {children}
    </MuiLink>
  );
}

Link.defaultProps = {
  external: false,
  state: null,
  variant: null,
};

Link.propTypes = {
  children: PropTypes.node.isRequired,
  external: PropTypes.bool,
  state: PropTypes.string,
  to: PropTypes.string.isRequired,
  variant: PropTypes.string,
};
