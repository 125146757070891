import React, { useState } from 'react';
// npm imports
import { useLocation } from 'react-router-dom';

// mui imports
import makeStyles from '@mui/styles/makeStyles';
import Avatar from '@mui/material/Avatar';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import LockResetIcon from '@mui/icons-material/LockReset';
// local imports
import { useAuth } from '@providers/AuthProvider';
import Link from '@components/shared/Link';
import AlreadyAuthorized from '@components/Account/Authorized/AlreadyAuthorized';
import LoadingAuth from '@components/Account/LoadingAuth';

const useStyles = makeStyles((theme) => ({
  image: {
    backgroundImage: 'url(/images/coors-field-background.jpg)', // TODO: move image to better server
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.mode === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  forgotPasswordForm: {
    padding: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export default function ForgotPassword() {
  const auth = useAuth();
  const classes = useStyles();
  const location = useLocation();

  const [forgotPasswordData, setForgotPasswordData] = useState({
    email: '',
  });

  const [isAuthorizing, setIsAuthorizing] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const [emailErrorMessage, setEmailErrorMessage] = useState(null);

  const handleTextFieldChange = (e) => {
    const { id, value } = e.target;

    // reset error messages when errored field is focused
    if (id === 'email') {
      setEmailErrorMessage(null);
    }

    setForgotPasswordData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const onSubmitForm = (e) => {
    e.preventDefault();

    setIsAuthorizing(true);

    // reset error messages when submitting
    setEmailErrorMessage(null);

    // basic email regex
    // TODO: move this into shared helper
    const emailRegex = new RegExp(
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
    );
    const isEmailValid = emailRegex.exec(forgotPasswordData?.email);

    const isAllDataValid =
      forgotPasswordData?.email.length !== 0 && isEmailValid;

    if (!isAllDataValid) {
      if (forgotPasswordData?.email.length === 0) {
        setEmailErrorMessage('Email address is required.');
      }
      if (!isEmailValid) {
        setEmailErrorMessage('Email address entered is not valid.');
      }

      setIsAuthorizing(false);
      return;
    }

    auth
      .sendPasswordResetEmail(forgotPasswordData.email)
      .then((userCredential) => {
        // set the same message for an error or for success
        // this helps to not give away our users information
        // to bad actors
        setIsAuthorizing(false);
        return setShowMessage(true);
      })
      .catch((error) => {
        // TODO: log error
        // set the same message for an error or for success
        // this helps to not give away our users information
        // to bad actors
        setIsAuthorizing(false);
        return setShowMessage(true);
      });
  };

  if (auth?.isLoading) {
    return <LoadingAuth />;
  }

  if (auth?.user) {
    return <AlreadyAuthorized />;
  }

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        {showMessage ? (
          <Grid container className={classes.forgotPasswordForm} spacing={4}>
            <Grid item xs={12}>
              <Grid
                container
                spacing={1}
                alignItems="center"
                direction="column"
              >
                <Grid item xs={12}>
                  <Avatar>
                    <ForwardToInboxIcon />
                  </Avatar>
                </Grid>
                <Grid item xs={12}>
                  <Typography align="center" component="h1" variant="h5">
                    Check your email to reset your password
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography align="center" p>
                If there's a stadiums.me account associated with{' '}
                {forgotPasswordData?.email ? (
                  <strong>{forgotPasswordData?.email}</strong>
                ) : (
                  'the provided email address,'
                )}{' '}
                you'll get a link in your inbox to reset your password.
              </Typography>
              <Typography align="center" p>
                If you don't get the link, check your spam folder or refresh
                this page to try again.
              </Typography>
              <Typography align="center" p>
                Still need help? <Link to="/contact">Contact us</Link>.
              </Typography>
              <Typography align="center" p>
                You can close this tab while you reset your password, or{' '}
                <Link to="/">return to the homepage</Link>.
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid container className={classes.forgotPasswordForm} spacing={4}>
            <Grid item>
              <Grid
                container
                spacing={1}
                alignItems="center"
                direction="column"
              >
                <Grid item>
                  <Avatar>
                    <LockResetIcon />
                  </Avatar>
                </Grid>
                <Grid item>
                  <Typography component="h1" variant="h5">
                    Forgot your password?
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <FormControl>
                <form onSubmit={onSubmitForm} noValidate>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        autoComplete="email"
                        autoFocus
                        disabled={isAuthorizing}
                        error={emailErrorMessage}
                        fullWidth
                        helperText={emailErrorMessage}
                        id="email"
                        label="Email Address"
                        name="email"
                        onChange={handleTextFieldChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <LoadingButton
                        color="primary"
                        fullWidth
                        loading={isAuthorizing}
                        type="submit"
                        variant="contained"
                      >
                        Send password reset email
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </form>
              </FormControl>
            </Grid>
            <Grid item>
              <Link to="/account/sign-in" state={{ from: location?.pathname }}>
                Remembered your password? Sign in
              </Link>
            </Grid>
            <Grid item>
              <Link to="/account/sign-up">Don't have an account? Sign Up</Link>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
