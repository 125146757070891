import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { stadiumType } from '@helpers/types';
// npm imports
import clsx from 'clsx';
// mui imports
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import DatePicker from '@mui/lab/DatePicker';
import LoadingButton from '@mui/lab/LoadingButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
// local imports
import { useAuth } from '@providers/AuthProvider';
import { addVisit } from 'client/api';

import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  addVisitDivider: {
    cursor: 'pointer',
    margin: `${theme.spacing(4, 0, 2, 0)}`, // TODO: useStyles.root issue - remove !important when possible
    '& .MuiDivider-wrapper': {
      display: 'flex',
    },
  },
}));

export default function AddVisitModal({
  handleDialogClose,
  isAddVisitModalOpen,
  stadium,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme?.breakpoints?.down('md'));

  const { account } = useAuth();
  const classes = useStyles();

  const { id: accountId } = account || {};
  const { id: venueId, name } = stadium || {};

  // form state and handlers
  // expand event info section
  const [eventInfoExpanded, setEventInfoExpanded] = useState(false);
  const handleEventInfoExpandClick = () => {
    setEventInfoExpanded(!eventInfoExpanded);
  };

  // expand seat info section
  const [seatInfoExpanded, setSeatInfoExpanded] = useState(false);
  const handleSeatInfoExpandClick = () => {
    setSeatInfoExpanded(!seatInfoExpanded);
  };

  const [dateValue, setDateValue] = useState(null);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const [isSaving, setIsSaving] = useState(false);


  // after error
  // opened / closed state for error toast
  const [isErrorOpen, setIsErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  // handles error toast closing
  const handleErrorClose = () => {
    setIsErrorOpen(false);
  };

  // submit visit form
  const onSubmitForm = (e) => {
    e.preventDefault();

    setIsSaving(true);

    addVisit({
      accountId,
      venueId,
      event: formData.event,
      date: dateValue ? dateValue : null,
      section: formData.section,
      row: formData.row,
      seat: formData.seat,
    })
      .then((response) => {
        setIsSaving(false);
        setEventInfoExpanded(false); // close event info section
        setSeatInfoExpanded(false); // close seat info section
        setDateValue(null); // reset date value
        setErrorMessage(null); // clear error message
        handleSuccessOpen(true);
        handleDialogClose();
      })
      .catch((error) => {
        console.error(error); // TODO: error messaging
        setErrorMessage(error);
        setIsSaving(false);
      });
  };

  // after success
  // opened / closed state for Success toast
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  // handles Success toast closing
  const handleSuccessClose = () => {
    setIsSuccessOpen(false);
  };
  // handles Success toast opening
  const handleSuccessOpen = () => {
    setIsSuccessOpen(true);
  };

  return (
    <>
      <Dialog
        open={isAddVisitModalOpen}
        onClose={handleDialogClose}
        aria-labelledby="add-visit-modal"
      >
        <DialogTitle id="add-visit-modal">Add a visit to {name}</DialogTitle>
        <form className={classes.form} onSubmit={onSubmitForm} noValidate>
          <DialogContent>
            <DialogContentText>
              If you'd like to add any <em>optional</em> info about your visit,
              such as date, event, or your seats, expand the sections below.
            </DialogContentText>
            <Divider
              flexItem={true}
              className={clsx(classes.addVisitDivider, {
                wrapper: classes.addVisitDividerWrapper,
              })}
              onClick={handleEventInfoExpandClick}
            >
              {eventInfoExpanded ? <RemoveIcon /> : <AddIcon />} Add event info
              (optional)
            </Divider>
            <Collapse in={eventInfoExpanded} timeout="auto" unmountOnExit>
              <TextField
                autoFocus
                margin="normal"
                id="event"
                label="Event name / info"
                type="input"
                onChange={handleChange}
                fullWidth
              />
              <DatePicker
                label="Date"
                id="date"
                value={dateValue}
                onChange={(newDateValue) => {
                  setDateValue(newDateValue);
                }}
                renderInput={(params) => (
                  <TextField margin="normal" id="date" fullWidth {...params} />
                )}
              />
            </Collapse>

            <Divider
              flexItem={true}
              className={clsx(classes.addVisitDivider, {
                wrapper: classes.addVisitDividerWrapper,
              })}
              onClick={handleSeatInfoExpandClick}
            >
              {seatInfoExpanded ? <RemoveIcon /> : <AddIcon />} Add seat info
              (optional)
            </Divider>
            <Collapse in={seatInfoExpanded} timeout="auto" unmountOnExit>
              <Grid container spacing={isMobile ? 0 : 2}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    autoFocus
                    margin="normal"
                    id="section"
                    label="Section"
                    type="text"
                    onChange={handleChange}
                    fullWidth={isMobile}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    margin="normal"
                    id="row"
                    label="Row"
                    type="text"
                    onChange={handleChange}
                    fullWidth={isMobile}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    margin="normal"
                    id="seat"
                    label="Seat"
                    type="text"
                    onChange={handleChange}
                    fullWidth={isMobile}
                  />
                </Grid>
              </Grid>
            </Collapse>
            {errorMessage && (
              <Alert className={classes.signUpError} severity="error">
                {errorMessage.message}
              </Alert>
            )}
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleDialogClose}>
              Cancel
            </Button>
            <LoadingButton
              loading={isSaving}
              loadingPosition="start"
              startIcon={<AddIcon />}
              type="submit"
              variant="contained"
            >
              Add visit
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
      <Snackbar
        open={isErrorOpen}
        autoHideDuration={10000}
        onClose={handleErrorClose}
      >
        <Alert onClose={handleErrorClose} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={isSuccessOpen}
        autoHideDuration={6000}
        onClose={handleSuccessClose}
      >
        <Alert onClose={handleSuccessClose} severity="success">
          Your visit to <strong>{name}</strong> was saved
        </Alert>
      </Snackbar>
    </>
  );
}

AddVisitModal.defaultProps = {};

AddVisitModal.propTypes = {
  handleDialogClose: PropTypes.func.isRequired,
  isAddVisitModalOpen: PropTypes.bool.isRequired,
  stadium: stadiumType.isRequired,
};
