import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
// npm imports
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
// mui imports
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Logout from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import Settings from '@mui/icons-material/Settings';
// local imports
import { useAuth } from '@providers/AuthProvider';
import { Flags } from '@providers/FlagsProvider';

// TODO: replace makeStyles
const useStyles = makeStyles((theme) => ({
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${theme.navigation.drawerWidth}px)`,
    marginLeft: theme.navigation.drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  logo: {
    flex: 1,
  },
}));

function NavigationAppBar({ handleDrawerOpen, isDrawerOpen }) {
  const auth = useAuth();
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints?.down('md'));
  const classes = useStyles();
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateToProfile = ({ route } = {}) => {
    navigate(route);
    handleClose();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <AppBar
      className={clsx(classes.appBar, {
        [classes.appBarShift]: isDrawerOpen,
      })}
      position="fixed"
    >
      <Toolbar>
        <Tooltip title="Open navigation menu">
          <IconButton
            aria-label="open navigation menu"
            className={clsx(classes.menuButton, isDrawerOpen && classes.hide)}
            color="inherit"
            edge="start"
            onClick={handleDrawerOpen}
            size="large"
          >
            <MenuIcon />
          </IconButton>
        </Tooltip>
        <Typography className={classes.logo} variant="h6" noWrap>
          STADIUMS.ME
        </Typography>
        <Flags authorizedFlags={['accountActions']}>
          {!isMobile && (
            <Button
              color="white"
              variant="text"
              onClick={() => navigateToProfile({ route: '/leaderboard' })}
              sx={{
                marginRight: !auth?.user ? theme.spacing(2) : theme.spacing(0),
              }}
            >
              Leaderboards
            </Button>
          )}
          {auth?.user && (
            <Fragment>
              {!isMobile && (
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{
                    margin: theme.spacing(2),
                    backgroundColor: '#ffffff',
                  }}
                />
              )}
              <Box alignItems="center" display="flex" textAlign="center">
                {!isMobile && (
                  <Fragment>
                    <Button
                      color="white"
                      variant="text"
                      onClick={() =>
                        navigateToProfile({ route: '/profile/visits' })
                      }
                    >
                      Visits
                    </Button>
                    <Button
                      color="white"
                      variant="text"
                      onClick={() =>
                        navigateToProfile({ route: '/profile/bucketlist' })
                      }
                    >
                      Bucket List
                    </Button>
                    <Button
                      color="white"
                      onClick={() =>
                        navigateToProfile({ route: '/profile/favorites' })
                      }
                      variant="text"
                    >
                      Favorites
                    </Button>
                  </Fragment>
                )}
                <Tooltip title="Open account menu">
                  <IconButton
                    aria-label="open account menu"
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                  >
                    <Avatar
                      sx={{ width: 32, height: 32 }}
                      alt={`${auth?.account?.firstName} ${auth?.account?.firstName}`}
                      src="https://pbs.twimg.com/profile_images/877631054525472768/Xp5FAPD5_reasonably_small.jg"
                    />
                  </IconButton>
                </Tooltip>
              </Box>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                {isMobile && (
                  <Fragment>
                    <MenuItem
                      onClick={() =>
                        navigateToProfile({ route: '/profile/visits' })
                      }
                    >
                      <ListItemIcon>
                        <Avatar fontSize="small" />
                      </ListItemIcon>
                      Visits
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        navigateToProfile({ route: '/profile/bucketlist' })
                      }
                    >
                      <ListItemIcon>
                        <PlaylistAddIcon fontSize="small" />
                      </ListItemIcon>
                      Bucket List
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        navigateToProfile({ route: '/profile/favorites' })
                      }
                    >
                      <ListItemIcon>
                        <FavoriteIcon fontSize="small" />
                      </ListItemIcon>
                      Favorites
                    </MenuItem>
                    <Divider />
                  </Fragment>
                )}
                <MenuItem
                  onClick={() => navigateToProfile({ route: '/profile' })}
                >
                  <Avatar /> Profile
                </MenuItem>
                <MenuItem
                  onClick={() => navigateToProfile({ route: '/account' })}
                >
                  <Avatar /> My account
                </MenuItem>
                <Divider />
                <MenuItem>
                  <ListItemIcon>
                    <Settings fontSize="small" />
                  </ListItemIcon>
                  Settings
                </MenuItem>
                <MenuItem onClick={() => auth.signOut()}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Sign out
                </MenuItem>
              </Menu>
            </Fragment>
          )}

          {!auth?.user && (
            <Button
              component={Link}
              color="inherit"
              to={'/account/sign-in'}
              variant="outlined"
            >
              Sign In
            </Button>
          )}
        </Flags>
      </Toolbar>
    </AppBar>
  );
}

NavigationAppBar.propTypes = {
  handleDrawerOpen: PropTypes.func.isRequired,
  isDrawerOpen: PropTypes.bool.isRequired,
};

export default NavigationAppBar;
