import React, { Fragment, useEffect, useState } from 'react';
// npm imports
import { useParams } from 'react-router-dom';
// local imports
import { getStadium } from '@api';
import PageTitle from '@components/shared/PageTitle';
import StadiumComponent from '@components/Stadium';
import { stadiumLocation } from '@helpers/locations';
import NotFound from '@pages/NotFound';

function Stadium() {
  const { stadiumSlug } = useParams();

  const [stadium, setStadium] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null); // TODO (error messaging): add error messaging if stadiums cannot be found

  useEffect(() => {
    if (stadiumSlug) {
      getStadium({
        stadiumSlug,
      })
        .then((response) => {
          setStadium(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setError(error); // TODO (error messaging): add error messaging if stadiums cannot be found
          setIsLoading(false);
        });
    }
  }, [stadiumSlug]);

  if (error) {
    // TODO (not found): better not found message
    return (
      <NotFound title="Not Found" subtitle="This stadium could not be found." />
    );
  }

  return (
    <Fragment>
      {stadium?.name && (
        <PageTitle
          title={`${stadium?.name} | ${
            stadium?.location &&
            stadiumLocation({ location: stadium?.location })
          }`}
        />
      )}
      <StadiumComponent isLoading={isLoading} stadium={stadium} />
    </Fragment>
  );
}

export default Stadium;
