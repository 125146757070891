import React from 'react';
import PropTypes from 'prop-types';
import { teamType } from '@helpers/types';
// npm imports
import { useNavigate } from 'react-router-dom';
// mui imports
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
// local imports
import Location from '@components/shared/Location';
import TeamStadiumsList from '../TeamStadiumsList';

const useStyles = makeStyles((theme) => ({
  cardMedia: {
    paddingTop: '56.25%', // 16:9
    backgroundSize: '56.25%',
  },
  cardMediaLoaded: {
    paddingTop: '56.25%', // 16:9
    backgroundSize: '200px 200px',
  },
  displayAsLink: {
    cursor: 'pointer',
  },
}));

function TeamCard({ isLoading, team }) {
  const classes = useStyles();

  const navigate = useNavigate();
  const handleOnClick = (route) => navigate(route);

  const imageUrl = `https://i.logocdn.com/${team?.leagueAbbr?.toLowerCase()}/current/${
    team?.slug
  }.svg`;

  return (
    <Card className={classes.root}>
      <CardHeader
        title={
          isLoading ? (
            <Skeleton animation="wave" width={195} />
          ) : (
            <Typography variant={'h6'}>{team?.name}</Typography>
          )
        }
        subheader={
          isLoading ? (
            <Skeleton animation="wave" width={140} />
          ) : (
            <Typography variant="overline">
              <Location location={team?.location} showCountry={false} />
            </Typography>
          )
        }
      />
      {isLoading ? (
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={classes.cardMedia}
        />
      ) : (
        <CardMedia
          className={classes.cardMediaLoaded}
          image={imageUrl}
          title={team?.name}
        />
      )}
      <CardContent>
        <TeamStadiumsList
          isLoading={isLoading}
          team={team}
          stadiums={team?.venues}
        />
      </CardContent>
    </Card>
  );
}

TeamCard.defaultProps = {
  isLoading: false,
  team: null,
};

TeamCard.propTypes = {
  isLoading: PropTypes.bool,
  team: teamType,
};

export default TeamCard;
