import PropTypes from 'prop-types';
// local imports
import { stadiumLocation } from '@helpers/locations';

function Location({ location, showCountry }) {
  return stadiumLocation({ location, showCountry });
}

Location.defaultProps = {
  location: null,
  showCountry: true,
};

Location.propTypes = {
  location: PropTypes.shape({
    address: PropTypes.string,
    city: PropTypes.string,
    region: PropTypes.string,
    postal: PropTypes.string,
    country: PropTypes.string,
    latitude: PropTypes.string,
    longitude: PropTypes.string,
  }),
  showCountry: PropTypes.bool,
};

export default Location;
