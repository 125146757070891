import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// local imports
import { getTeams } from '@api';
import PageTitle from '@components/shared/PageTitle';
import Error from '@components/Error';
import Hero from '@components/shared/Hero';
import TeamsComponent from '@components/Teams';

function Teams({ league }) {
  const [teamsData, setTeamsData] = useState(null);
  // provide 6 blank objects to display Skeleton during loading, with id's to satisfy React keys
  const [teams, setTeams] = useState([
    { id: '1' },
    { id: '2' },
    { id: '3' },
    { id: '4' },
    { id: '5' },
    { id: '6' },
  ]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    getTeams({ league })
      .then((response) => {
        setTeamsData(response.data);
        setTeams(response.data.franchises);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error.response.data);
        setIsLoading(false);
      });
  }, [league]);

  if (error) {
    // TODO (error): better error messaging
    return <Error error={error} />;
  }

  return (
    <Fragment>
      {league && (
        <PageTitle
          title={`Browse Stadiums by ${league.toUpperCase()} Team`}
          description={`${teamsData?.description} Browse their current, future, and historical stadiums here.`}
        />
      )}
      <Hero
        title={`Browse Stadiums by ${league.toUpperCase()} Team`}
        description={`${teamsData?.description} Overall, we track ${
          teamsData?.venueCount
        } stadiums which have historically hosted one or more regular season or playoff ${league.toUpperCase()} games.`}
        isLoading={isLoading}
      />
      <TeamsComponent isLoading={isLoading} teams={teams} />
    </Fragment>
  );
}

Teams.defaultProps = {
  league: null,
};

Teams.propTypes = {
  league: PropTypes.string,
};

export default Teams;
