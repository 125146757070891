import React from 'react';
// mui imports
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
// local imports
import Link from '@components/shared/Link';

function NotFound() {
  return (
    <Grid
      container
      alignItems="center"
      direction="column"
      justifyContent="center"
      sx={{ padding: (theme) => theme.spacing(10) }}
    >
      <Grid item xs={12}>
        <Typography variant="h2" component="h1">
          Page not found.
        </Typography>
        <Divider sx={{ margin: (theme) => theme.spacing(4, 0) }} />
        <Link to="/">Go to the homepage.</Link>
      </Grid>
    </Grid>
  );
}

export default NotFound;
