import React from 'react';
import PropTypes from 'prop-types';
// mui imports
import makeStyles from '@mui/styles/makeStyles';
import Avatar from '@mui/material/Avatar';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';

const useStyles = makeStyles((theme) => ({
  transparentBackground: {
    backgroundColor: 'transparent',
  },
}));

export default function TeamAvatar({ isLoading, team }) {
  const classes = useStyles();
  const { abbr, league, teamName, year } = team;
  const showYearForLogo = year !== null && year > 0; // TODO: remove year ? 0 once audit of logos is complete

  if (isLoading) {
    return (
      <Skeleton animation="wave">
        <Avatar />
      </Skeleton>
    );
  }

  return (
    <Tooltip
      title={`${teamName} ${showYearForLogo ? `(${year} logo)` : ''}`}
      arrow
    >
      <Avatar
        className={classes.transparentBackground}
        variant="square"
        style={{ backgroundColor: 'transparent' }}
      >
        <i
          alt={`${teamName} ${showYearForLogo ? `(${year} logo)` : ''}`}
          className={`logo-icon logo-${league?.toLowerCase()}-${abbr?.toLowerCase()}${
            showYearForLogo ? `-${year}` : ''
          }`}
          style={{ height: '95%', width: '95%' }}
        />
      </Avatar>
    </Tooltip>
  );
}

TeamAvatar.defaultProps = {
  isLoading: false,
  team: {},
};

TeamAvatar.propTypes = {
  isLoading: PropTypes.bool,
  team: PropTypes.shape({
    abbr: PropTypes.string,
    league: PropTypes.string,
    teamName: PropTypes.string,
    year: PropTypes.string,
  }),
};
