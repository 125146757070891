import React from 'react';
// mui imports
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
// local imports
import Link from '@components/shared/Link';

function Footer() {
  return (
    <Paper
      sx={{
        marginTop: (theme) => theme.spacing(6),
        padding: (theme) => theme.spacing(6),
      }}
    >
      <Typography align="center" component="p" variant="h6" gutterBottom>
        stadiums.me
      </Typography>
      <Typography
        align="center"
        color="textSecondary"
        component="p"
        variant="subtitle1"
      >
        The easiest way to track which MLB, NBA, NFL and NHL stadiums you've
        visited.
      </Typography>
      <Typography
        align="center"
        color="textSecondary"
        gutterbottomvariant="body2"
      >
        {'© 2014 - '}
        {new Date().getFullYear()}{' '}
        <Link to="https://codeandsons.com" external={true}>
          Code & Sons
        </Link>
        {'. All rights reserved.'}
      </Typography>
      <Typography
        align="center"
        color="textSecondary"
        sx={{ marginTop: (theme) => theme.spacing(2) }}
        variant="body2"
      >
        <Link to="/legal/terms-of-use">Terms of Use</Link> |{' '}
        <Link to="/legal/privacy-policy">Privacy Policy</Link> |{' '}
        <Link to="/legal/cookie-policy">Cookie Policy</Link>
      </Typography>
    </Paper>
  );
}

export default Footer;
