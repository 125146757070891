import React from 'react';
// local imports
import PageTitle from '@components/shared/PageTitle';
import AppContainer from '@layouts/AppContainer';
import AppRoutes from '@layouts/AppRoutes';
import Footer from '@layouts/Footer';

function AppLayout() {
  return (
    <AppContainer>
      <PageTitle />
      <AppRoutes />
      <Footer />
    </AppContainer>
  );
}
export default AppLayout;
