import PropTypes from 'prop-types';

/**
 * @file reusable PropType definitions. Within this file, there are shared types as well, which should be listed first.
 */

/**
 * @name teamType
 * @description PropTypes for a team object
 */
export const teamType = PropTypes.shape({
  abbr: PropTypes.string,
  id: PropTypes.string,
  founded: PropTypes.string,
  leagueAbbr: PropTypes.string,
  leagueName: PropTypes.string,
  name: PropTypes.string,
  slug: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      title: PropTypes.string,
      link: PropTypes.string,
    })
  ),
  location: PropTypes.shape({
    city: PropTypes.string,
    region: PropTypes.string,
    country: PropTypes.string,
  }),
  teams: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      start: PropTypes.string,
      end: PropTypes.string,
    })
  ),
  venues: PropTypes.arrayOf(
    PropTypes.shape({
      venueId: PropTypes.string,
      franchiseId: PropTypes.string,
      teamId: PropTypes.string,
      teamName: PropTypes.string,
      occupancyStatus: PropTypes.string,
      start: PropTypes.string,
      end: PropTypes.string,
      stints: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          venueId: PropTypes.string,
          franchiseId: PropTypes.string,
          teamId: PropTypes.string,
          start: PropTypes.string,
          end: PropTypes.string,
          occupancyStatus: PropTypes.string,
          type: PropTypes.string,
          typeReason: PropTypes.string,
          name: PropTypes.string,
        })
      ),
      venueName: PropTypes.string,
      venueSlug: PropTypes.string,
    })
  ),
});

/**
 * @name franchiseType
 * @description PropTypes for a franchise object
 */
export const franchiseType = PropTypes.shape({
  franchiseId: PropTypes.string,
  franchiseName: PropTypes.string,
  abbr: PropTypes.string,
  league: PropTypes.string,
  occupancyStatus: PropTypes.string,
  status: PropTypes.string,
  start: PropTypes.string,
  end: PropTypes.string,
  teams: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      franchiseId: PropTypes.string,
      teamId: PropTypes.string,
      team_id: PropTypes.string,
      start: PropTypes.string,
      end: PropTypes.string,
      full_time: PropTypes.string,
      tenantActive: PropTypes.string,
      franchiseName: PropTypes.string,
      league: PropTypes.string,
      abbr: PropTypes.string,
      teamName: PropTypes.string,
      teamActive: PropTypes.string,
      occupancyStatus: PropTypes.string,
      status: PropTypes.string,
      teamStatus: PropTypes.string,
    })
  ),
});

/**
 * @name profileType
 * @description PropTypes for a profile object
 */
export const profileType = PropTypes.shape({
  id: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  joined: PropTypes.date,
  bucketlist: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      venueId: PropTypes.string,
      accountId: PropTypes.string,
      venueName: PropTypes.string,
      venueSlug: PropTypes.string,
      added: PropTypes.date,
      updated: PropTypes.date,
    })
  ),
  favorites: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      venueId: PropTypes.string,
      accountId: PropTypes.string,
      venueName: PropTypes.string,
      venueSlug: PropTypes.string,
      added: PropTypes.date,
      updated: PropTypes.date,
    })
  ),
  visits: PropTypes.shape({
    totalVisitsCount: PropTypes.number,
    allVisits: PropTypes.number,
    currentStadiumVisitsCount: PropTypes.number,
    mlb: PropTypes.shape({
      currentStadiumVisitsCount: PropTypes.number,
    }),
    nba: PropTypes.shape({
      currentStadiumVisitsCount: PropTypes.number,
    }),
    nfl: PropTypes.shape({
      currentStadiumVisitsCount: PropTypes.number,
    }),
    nhl: PropTypes.shape({
      currentStadiumVisitsCount: PropTypes.number,
    }),
    visits: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        venueId: PropTypes.string,
        accountId: PropTypes.string,
        eventId: PropTypes.string,
        date: PropTypes.date,
        event: PropTypes.string,
        row: PropTypes.string,
        seat: PropTypes.string,
        section: PropTypes.string,
        added: PropTypes.date,
        updated: PropTypes.date,
      })
    ),
  }),
});

/**
 * stadiumType
 * this type includes the following subTypes:
 * constructionType, eventsType, linksType, locationType, namesType, tenantsType
 */

/**
 * @name constructionType
 * @description PropTypes for a construction object
 */
export const constructionType = PropTypes.shape({
  status: PropTypes.string,
  groundbreaking: PropTypes.string,
  opened: PropTypes.string,
  closed: PropTypes.string,
  demolished: PropTypes.string,
  precision: PropTypes.shape({
    groundbreaking: PropTypes.string,
    opened: PropTypes.string,
    closed: PropTypes.string,
    demolished: PropTypes.string,
  }),
});

/**
 * @name eventsType
 * @description PropTypes for a events object
 */
export const eventsType = PropTypes.arrayOf(
  PropTypes.shape({
    status: PropTypes.string,
    groundbreaking: PropTypes.string,
    opened: PropTypes.string,
    closed: PropTypes.string,
    demolished: PropTypes.string,
    precision: PropTypes.shape({
      groundbreaking: PropTypes.string,
      opened: PropTypes.string,
      closed: PropTypes.string,
      demolished: PropTypes.string,
    }),
  })
);

/**
 * @name linksType
 * @description PropTypes for a links object
 */
export const linksType = PropTypes.arrayOf(
  PropTypes.shape({
    link: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
  })
);

/**
 * @name locationType
 * @description PropTypes for a location object
 */
export const locationType = PropTypes.shape({
  address: PropTypes.string,
  city: PropTypes.string,
  region: PropTypes.string,
  postal: PropTypes.string,
  country: PropTypes.string,
  latitude: PropTypes.string,
  longitude: PropTypes.string,
  timezone: PropTypes.string,
});

/**
 * @name namesType
 * @description PropTypes for a names object
 */
export const namesType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string,
    end: PropTypes.string,
    name: PropTypes.string,
    nickname: PropTypes.string,
    start: PropTypes.string,
    status: PropTypes.string,
    venueId: PropTypes.string,
  })
);

/**
 * @name tenants
 * @description PropTypes for a tenants object
 */
export const tenantsType = PropTypes.arrayOf(
  PropTypes.shape({
    franchiseId: PropTypes.string,
    franchiseName: PropTypes.string,
    abbr: PropTypes.string,
    league: PropTypes.string,
    occupancyStatus: PropTypes.string,
    start: PropTypes.string,
    end: PropTypes.string,
    teams: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        tenantId: PropTypes.string,
        franchiseId: PropTypes.string,
        teamId: PropTypes.string,
        start: PropTypes.string,
        end: PropTypes.string,
        full_time: PropTypes.string,
        occupancyStatus: PropTypes.string,
        type: PropTypes.string,
        typeReason: PropTypes.string,
        franchiseName: PropTypes.string,
        league: PropTypes.string,
        abbr: PropTypes.string,
        teamName: PropTypes.string,
        teamStatus: PropTypes.string,
      })
    ),
    reason: PropTypes.string,
  })
);

/**
 * @name stadiumType
 * @description PropTypes for a stadium object, contains the following subtypes:
 * constructionType, eventsType, linksType, locationType, namesType, tenantsType
 */
export const stadiumType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  nickname: PropTypes.string,
  slug: PropTypes.string,
  status: PropTypes.string,
  construction: constructionType,
  events: eventsType,
  links: linksType,
  location: locationType,
  names: namesType,
  tenants: tenantsType,
});
