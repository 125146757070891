import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// mui imports
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
// local imports
import { getStadiums } from 'client/api';
import StadiumCard from '@components/Stadium/StadiumCard';
import NotFound from '@pages/NotFound';
import Hero from '@components/shared/Hero';

export default function Stadiums({ filter, league, status }) {
  const [stadiums, setStadiums] = useState([
    { id: '1' },
    { id: '2' },
    { id: '3' },
    { id: '4' },
    { id: '5' },
    { id: '6' },
  ]); // provide 6 blank objects to display Skeleton during loading, with id's to satisfy React keys
  const [stadiumsData, setStadiumsData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    getStadiums({ filter, league, status })
      .then((response) => {
        setStadiumsData(response.data);
        setStadiums(response.data.stadiums);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setError(error);
        setIsLoading(false);
      });
  }, [filter, league, status]);

  if (error) {
    // TODO: better not found message
    return (
      <NotFound title="Not Found" subtitle="This page could not be found." />
    );
  }

  return (
    <Fragment>
      <Hero
        description={stadiumsData?.description}
        filter={filter}
        isLoading={isLoading}
        league={league}
        stadiumCount={stadiumsData?.stadiumCount}
        type="stadium"
      />
      <Container max="lg" disableGutters>
        <Grid container spacing={2}>
          {stadiums?.map((stadium) => (
            <Grid item key={stadium.id} xs={12} sm={6} md={4}>
              <StadiumCard
                isLoading={isLoading}
                filter={filter}
                stadium={stadium}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Fragment>
  );
}

Stadiums.defaultProps = {
  filter: 'current',
  league: null,
  status: 'current',
};

Stadiums.propTypes = {
  filter: PropTypes.string,
  league: PropTypes.string,
  status: PropTypes.string,
};
