import React from 'react';
import PropTypes from 'prop-types';
// npm imports
import { Helmet } from 'react-helmet';
// local imports
import { GLOBAL_DESCRIPTION, GLOBAL_TITLE } from '@helpers/constants';

export default function PageTitle({ description, title }) {
  if (title) {
    return (
      <Helmet>
        <title>
          {title} | {GLOBAL_TITLE}
        </title>
        <meta name="description" content={description} />
      </Helmet>
    );
  }

  return (
    <Helmet>
      <title>{title ? title : GLOBAL_TITLE}</title>
      <meta
        name="description"
        content={description ? description : GLOBAL_DESCRIPTION}
      />
    </Helmet>
  );
}

PageTitle.defaultProps = {
  description: null,
  title: null,
};

PageTitle.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
};
