import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
// npm imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWikipediaW } from '@fortawesome/free-brands-svg-icons';
import { formatInTimeZone } from 'date-fns-tz';
// mui imports
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';

const columns = [
  {
    field: 'eventDate',
    headerName: 'Date',
    description: 'Date of event.',
    minWidth: 175,
    maxWidth: 175,
    width: 175,
    valueFormatter: (params) => {
      return formatInTimeZone(
        new Date(params.value),
        'Etc/UTC',
        'MMMM d, yyyy'
      );
    },
  },
  {
    field: 'eventName',
    headerName: 'Event',
    description: 'Name of event.',
    flex: 1,
    minWidth: 200,
  },
  {
    field: 'wikipediaLink',
    headerName: 'Links',
    description: 'Wikipedia link for event.',
    minWidth: 125,
    maxWidth: 125,
    width: 125,
    // eslint-disable-next-line
    renderCell: (params) => {
      return (
        <Tooltip title="Event wikipedia page" arrow>
          <IconButton
            aria-label="Event wikipedia page"
            href={params.value}
            target="_blank"
            size="large"
          >
            <FontAwesomeIcon icon={faWikipediaW} fixedWidth />
          </IconButton>
        </Tooltip>
      );
    },
  },
];

const useStyles = makeStyles((theme) => ({
  // TODO: add cardHeader style to shared style file
  cardHeader: {
    background: theme.palette.secondary.main,
  },
}));

export default function StadiumEvents({ isLoading, events }) {
  const classes = useStyles();

  const rows = events?.map((event) => {
    return {
      id: event.id,
      eventDate: event.date,
      eventName: event.event,
      wikipediaLink: event.wikipedia,
    };
  });

  return (
    <Card>
      <CardHeader
        avatar={
          isLoading ? (
            <Skeleton
              animation="wave"
              variant="circular"
              width={24}
              height={24}
            />
          ) : (
            <EventSeatIcon />
          )
        }
        className={classes.cardHeader}
        title={
          isLoading ? (
            <Skeleton animation="wave" width={120} />
          ) : (
            'Notable Events'
          )
        }
      />
      <CardContent>
        {isLoading ? (
          <Typography variant="body2">
            <Skeleton animation="wave" width={275} />
          </Typography>
        ) : rows?.length ? (
          <Fragment>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={32}
              disableSelectionOnClick
              hideFooter
              autoHeight={true}
              loading={isLoading}
            />
          </Fragment>
        ) : (
          <Typography variant="body2">
            Sorry, we can't find any notable events for this stadium at this
            time.
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}

StadiumEvents.defaultProps = {
  isLoading: false,
  events: [],
};

StadiumEvents.propTypes = {
  isLoading: PropTypes.bool,
  events: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      date: PropTypes.string,
      event: PropTypes.string,
      wikipedia: PropTypes.string,
    })
  ),
};
