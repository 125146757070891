import React, { Fragment } from 'react';
// mui imports
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
// local imports
import Hero from '@components/shared/Hero';
import Link from '@components/shared/Link';
import PageTitle from '@components/shared/PageTitle';

function Contact() {
  return (
    <Fragment>
      <PageTitle title="Contact" />
      <Hero title="Contact" />
      <Container max="lg" disableGutters>
        <Typography>
          Contact us via{' '}
          <Link to="https://aemail.com/n5v4" external={true}>
            Email
          </Link>{' '}
          or{' '}
          <Link to="https://twitter.com/mestadiums" external={true}>
            Twitter
          </Link>
          .
        </Typography>
      </Container>
    </Fragment>
  );
}

export default Contact;
