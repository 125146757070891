import callAPI from './callAPI';

/**
 * @name getStadiums
 * @description get a list of stadiums
 * @param {string} filter stadium status to filter by
 * @param {string} league league to filter by
 * @param {string} status status to filter by
 * @returns {object} response object
 */
export const getStadiums = ({ filter, league, status }) => {
  return callAPI({
    url: '/stadiums/', // TODO: remove trailing slash once that's figure out
    params: { filter, league, status },
  });
};
