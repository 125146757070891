export const flags = [
  {
    name: 'defaultTestFlag',
    isActive: true,
  },
  {
    name: 'accountActions',
    isActive: process.env.REACT_APP_FLAG_ACCOUNT_ACTIONS === 'true',
  },
  {
    name: 'browseByTeam',
    isActive: process.env.REACT_APP_FLAG_BROWSE_BY_TEAM === 'true',
  },
  {
    name: 'competeWithFriends',
    isActive: process.env.REACT_APP_FLAG_COMPETE_WITH_FRIENDS === 'true',
  },
  {
    name: 'multipleVisits',
    isActive: process.env.REACT_APP_FLAG_MULTIPLE_VISITS === 'true',
  },
  {
    name: 'notableEvents',
    isActive: process.env.REACT_APP_FLAG_NOTABLE_EVENTS === 'true',
  },
];
