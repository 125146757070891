import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { stadiumType } from '@helpers/types';
// npm imports
import clsx from 'clsx';
// mui imports
import makeStyles from '@mui/styles/makeStyles';
import { grey } from '@mui/material/colors';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// local imports
import { useAuth } from '@providers/AuthProvider';
import Link from '@components/shared/Link';
import { getVisits } from 'client/api';
import VisitsTable from './VisitsTable';
import BucketlistsIcon from './BucketlistsIcon';
import FavoritesIcon from './FavoritesIcon';
import VisitsIcon from './VisitsIcon';

const useStyles = makeStyles((theme) => ({
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  activeIcon: {
    color: theme.palette.secondary.main,
  },
  inactiveIcon: {
    color: grey[300],
  },
  loadingIcon: {
    margin: '12px',
  },
}));

export default function VisitorCard({ isLoading, stadium, venueId }) {
  const { account, isLoading: isAuthLoading } = useAuth();
  const classes = useStyles();

  // visits
  const [visits, setVisits] = useState(null);
  const [visitsData, setVisitsData] = useState(null);
  const [isVisitsLoading, setIsVisitsLoading] = useState(true);
  const [visitsError, setVisitsError] = useState(null);

  useEffect(() => {
    if (account && venueId) {
      getVisits({
        accountId: account.id,
        venueId,
      })
        .then((response) => {
          setVisitsData(response.data);
          setVisits(response.data.visits);
          setIsVisitsLoading(false);
        })
        .catch((error) => {
          console.error(error); // TODO: error messaging
          setVisitsError(error);
          setIsVisitsLoading(false);
        });
    }
  }, [account, venueId]);

  // expand previous visits section
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  // check all loading status together to make sure everything is ready
  const isAnyLoading = isLoading || isVisitsLoading;

  return (
    <Grid container>
      <Grid item xs={12}>
        {account ? (
          <Card className={classes.root}>
            <CardHeader
              avatar={
                isLoading ? (
                  <Skeleton
                    animation="wave"
                    variant="circular"
                    width={40}
                    height={40}
                  />
                ) : (
                  <Avatar
                    alt={`${account?.firstName} ${account?.firstName}`}
                    src="https://pbs.twimg.com/profile_images/877631054525472768/Xp5FAPD5_reasonably_small.jg"
                  />
                )
              }
              title={
                isLoading ? (
                  <Skeleton
                    animation="wave"
                    height={10}
                    width="80%"
                    style={{ marginBottom: 6 }}
                  />
                ) : (
                  account?.firstName
                )
              }
              subheader={
                isLoading ? (
                  <Skeleton animation="wave" height={10} width="40%" />
                ) : (
                  '5 hours ago'
                )
              }
            />
            <CardContent>
              {visitsError ? (
                <Alert severity="error">
                  We're having trouble loading your visit history right now.
                </Alert>
              ) : isAnyLoading ? (
                <Fragment>
                  <Skeleton
                    animation="wave"
                    height={10}
                    style={{ marginBottom: 6 }}
                  />
                  <Skeleton animation="wave" height={10} width="80%" />
                </Fragment>
              ) : (
                <Typography variant="body2" color="textSecondary" component="p">
                  {visitsData?.visitsCount > 0 ? (
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      spacing={2}
                      nowrap
                    >
                      <Grid item>
                        <BeenhereIcon className={classes.activeIcon} />
                      </Grid>
                      <Grid item alignSelf="flex-start">
                        You've visited this stadium {visitsData?.visitsCount}{' '}
                        time{visitsData?.visitsCount > 1 ? 's' : null}!
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      spacing={2}
                      nowrap
                    >
                      <Grid item>
                        <BeenhereIcon className={classes.inactiveIcon} />
                      </Grid>
                      <Grid item alignSelf="flex-start">
                        You haven't visited this stadium yet.
                      </Grid>
                    </Grid>
                  )}
                </Typography>
              )}
            </CardContent>
            {!visitsError && (
              <CardActions disableSpacing>
                <VisitsIcon stadium={stadium} venueId={venueId} />
                <BucketlistsIcon stadium={stadium} venueId={venueId} />
                <FavoritesIcon stadium={stadium} venueId={venueId} />
                {isAnyLoading ? (
                  <Skeleton
                    animation="wave"
                    variant="circular"
                    className={classes.loadingIcon}
                    width={24}
                    height={24}
                  />
                ) : visitsData?.visitsCount ? (
                  <Tooltip title="See previous visits" arrow>
                    <IconButton
                      className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded,
                      })}
                      onClick={handleExpandClick}
                      aria-expanded={expanded}
                      aria-label="show more"
                      size="large"
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                  </Tooltip>
                ) : null}
              </CardActions>
            )}
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent>
                <VisitsTable
                  isVisitsLoading={isVisitsLoading}
                  stadium={stadium}
                  visits={visits}
                  visitsCount={visitsData?.visitsCount}
                />
              </CardContent>
            </Collapse>
          </Card>
        ) : (
          <Card className={classes.root}>
            <CardHeader
              avatar={
                isLoading ? (
                  <Skeleton
                    animation="wave"
                    variant="circular"
                    width={40}
                    height={40}
                  />
                ) : (
                  <Avatar />
                )
              }
              title={
                isAuthLoading ? (
                  <Skeleton
                    animation="wave"
                    height={10}
                    width="80%"
                    style={{ marginBottom: 6 }}
                  />
                ) : (
                  <Fragment>
                    <Link to="/account/sign-in">Sign in</Link> to keep track of
                    your visits
                  </Fragment>
                )
              }
            />
          </Card>
        )}
      </Grid>
    </Grid>
  );
}

VisitorCard.defaultProps = {
  isLoading: false,
  stadium: {},
  venueId: null,
};

VisitorCard.propTypes = {
  isLoading: PropTypes.bool,
  stadium: stadiumType,
  venueId: PropTypes.string,
};
