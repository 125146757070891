import React from 'react';
import PropTypes from 'prop-types';
import { franchiseType } from '@helpers/types';
// mui imports
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import Skeleton from '@mui/material/Skeleton';
// local imports
import TeamListItem from '@components/Team/TeamListItem';

export default function TenantsList({ isLoading, tenants }) {
  const currentTenants = tenants?.filter(
    (tenant) => tenant.occupancyStatus === 'current'
  );

  const historicalTenants = tenants?.filter(
    (tenant) => tenant.occupancyStatus === 'historical'
  );

  const futureTenants = tenants?.filter(
    (tenant) => tenant.occupancyStatus === 'future'
  );

  return (
    <>
      {currentTenants && currentTenants.length > 0 && (
        <List
          aria-labelledby="current-tenants-list"
          subheader={
            <ListSubheader id="current-tenants-list" disableGutters>
              {isLoading ? (
                <Skeleton animation="wave" width={95} />
              ) : (
                'Current Tenants'
              )}
            </ListSubheader>
          }
        >
          {currentTenants.map((tenant) => (
            <TeamListItem
              isLoading={isLoading}
              franchise={tenant}
              key={tenant.franchiseId}
            />
          ))}
        </List>
      )}
      {futureTenants && futureTenants.length > 0 && (
        <List
          aria-labelledby="future-tenants-list"
          subheader={
            <ListSubheader id="future-tenants-list" disableGutters>
              {isLoading ? (
                <Skeleton animation="wave" width={95} />
              ) : (
                'Future Tenants'
              )}
            </ListSubheader>
          }
        >
          {futureTenants.map((tenant) => (
            <TeamListItem
              isLoading={isLoading}
              franchise={tenant}
              key={tenant.franchiseId}
            />
          ))}
        </List>
      )}
      {historicalTenants && historicalTenants.length > 0 && (
        <List
          aria-labelledby="historical-tenants-list"
          subheader={
            <ListSubheader id="historical-tenants-list" disableGutters>
              {isLoading ? (
                <Skeleton animation="wave" width={95} />
              ) : (
                'Historical Tenants'
              )}
            </ListSubheader>
          }
        >
          {historicalTenants.map((tenant) => (
            <TeamListItem
              isHistoricalTeam={true}
              isLoading={isLoading}
              franchise={tenant}
              key={tenant.franchiseId}
            />
          ))}
        </List>
      )}
    </>
  );
}

TenantsList.defaultProps = {
  isLoading: false,
  tenants: [
    { franchiseId: '1', occupancyStatus: 'current' },
    { franchiseId: '2', occupancyStatus: 'current' },
  ], // provide 2 blank objects to display Skeleton during loading, with id's to satisfy React keys
};

TenantsList.propTypes = {
  isLoading: PropTypes.bool,
  tenants: PropTypes.arrayOf(franchiseType), // TODO: update this to be franchise, or update other to be tenants
};
