import callAPI from './callAPI';

/**
 * @name getLeaderboard
 * @description get stadiums.me leaderboard
 * @param {string} type type of leaderboard
 * @returns {object} response object
 */
export const getLeaderboard = ({ type }) => {
  return callAPI({
    url: '/leaderboard/', // TODO: remove trailing slash once that's figure out
    params: { type },
  });
};
