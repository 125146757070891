import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/auth';
import qs from 'qs';

/**
 * @name callAPI
 * @description API wrapper to provide consistent responses no matter the underlying fetch tech used.
 * @param {object} data added to the body of a request for DELETE / PATCH / POST / PUT actions
 * @param {object} headers
 * @param {string} method REST method
 * @param {object} params added to the request string for GET actions
 * @param {boolean} skipAuth skip sending Authorization header
 * @param {string} url endpoint url
 * @param {string} version API version
 * @returns {object} response object
 */
const callAPI = async ({
  data = {},
  headers = {},
  method = 'get',
  params = {},
  skipAuth = false,
  url,
  version = 'v1',
}) => {
  const token = await firebase.auth().currentUser?.getIdToken();

  const headersToPass = {
    'content-type': 'application/x-www-form-urlencoded',
    ...headers,
    Authorization: `Bearer ${token}`,
  };

  if (skipAuth) {
    delete headersToPass.Authorization;
  }

  const response = await axios({
    baseURL: `https://api.stadiums.me/${version}/`,
    data: qs.stringify(data),
    headers: headersToPass,
    method,
    params,
    url,
  });

  return response;
};

export default callAPI;
