import React, { useEffect, useState } from 'react';
// npm imports
import { Image } from 'mui-image';
import { useNavigate } from 'react-router-dom';
// mui imports
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
// local imports
import { getStadiums } from '@api';
import Link from '@components/shared/Link';
import Hero from '@components/shared/Hero';
import Map from '@components/shared/Map';
import { useAuth } from '@providers/AuthProvider';
import { Flags } from '@providers/FlagsProvider';

function Home() {
  const { account } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme?.breakpoints?.down('md'));

  const [stadiumsData, setStadiumsData] = useState(null);
  const stadiums = stadiumsData?.stadiums;
  const [isStadiumsLoading, setIsStadiumsLoading] = useState(true);
  // const [error, setError] = useState(false); // TODO (error messaging): add error messaging if stadiums cannot be found

  useEffect(() => {
    getStadiums({})
      .then((response) => {
        setStadiumsData(response.data);
        setIsStadiumsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        // setError(error); // TODO (error messaging): add error messaging if stadiums cannot be found
        setIsStadiumsLoading(false);
      });
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Container max="xl" disableGutters>
          <Grid container spacing={8}>
            <Grid item xs={0} md={2}>
              {/* This is for spacing only */}
            </Grid>
            <Grid item xs={12} md={4}>
              <Image
                alt="stadiums.me logo"
                src={
                  isMobile
                    ? '/logos/logo-primary-rectangle.png'
                    : '/logos/logo-primary.png'
                }
                shift="right"
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Typography
                component="h2"
                variant="h4"
                sx={{ textTransform: 'uppercase' }}
                gutterBottom
              >
                Hey there, sports fans!
              </Typography>
              <Typography component="h2" variant="h6" gutterBottom>
                Dreamed of visiting every stadium out there?
              </Typography>
              <Typography paragraph>
                Stadiums.me helps you track your visits to every MLB, NBA, NFL,
                and NHL teams' stadiums, both past & present.
              </Typography>
              <Typography paragraph>
                Keep track of your own visits, and compete with friends to see
                who can visit the most.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Grid item xs={12}>
        <Hero type="image" />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={8}>
          <Grid
            item
            xs={12}
            md={4}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Typography
              component="h2"
              variant="h4"
              sx={{ textTransform: 'uppercase' }}
              gutterBottom
            >
              {isStadiumsLoading ? (
                <Skeleton animation="wave" />
              ) : (
                'Track your stadium visits'
              )}
            </Typography>
            <Typography paragraph>
              {isStadiumsLoading ? (
                <>
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" width="25%" />
                </>
              ) : (
                `We currently have ${stadiumsData?.stadiumCount} venues in our database as current and historical homes for all 124 professional teams, with more being added all the time.`
              )}
            </Typography>
            {isStadiumsLoading ? (
              <Typography paragraph>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" width="25%" />
              </Typography>
            ) : (
              <Typography paragraph>
                From{' '}
                <Link to="/stadium/mile-high-stadium">
                  <i
                    alt="Denver Broncos logo 1996"
                    className="logo-icon logo-nfl-den-1996"
                    style={{ width: '24px', height: '24px' }}
                  />{' '}
                  Mile High Stadium
                </Link>{' '}
                to{' '}
                <Link to="/stadium/empower-field-at-mile-high">
                  <i
                    alt="Denver Broncos current logo"
                    className="logo-icon logo-nfl-den"
                    style={{ width: '24px', height: '24px' }}
                  />{' '}
                  Empower Field
                </Link>
                , from{' '}
                <Link to="/stadium/ebbets-field">
                  <i
                    alt="Brooklyn Dodgers 1932 logo"
                    className="logo-icon logo-mlb-lad-1932"
                    style={{ width: '24px', height: '24px' }}
                  />{' '}
                  Ebbets Field
                </Link>{' '}
                to{' '}
                <Link to="/stadium/dodger-stadium">
                  <i
                    alt="Los Angeles Dodgers current logo"
                    className="logo-icon logo-mlb-lad"
                    style={{ width: '24px', height: '24px' }}
                  />{' '}
                  Dodger Stadium
                </Link>
                , from{' '}
                <Link to="/stadium/buffalo-memorial-auditorium">
                  <i
                    alt="Buffalo Braves 1978 logo"
                    className="logo-icon logo-nba-lac-1978"
                    style={{ width: '24px', height: '24px' }}
                  />{' '}
                  Buffalo Memorial Auditorium
                </Link>{' '}
                to{' '}
                <Link to="/stadium/crypto-arena">
                  <i
                    alt="Los Angeles Clippers current logo"
                    className="logo-icon logo-nba-lac"
                    style={{ width: '24px', height: '24px' }}
                  />{' '}
                  Crypto.com Arena
                </Link>{' '}
                and{' '}
                <Link to="/stadium/intuit-dome">
                  <i
                    alt="Los Angeles Clippers current logo"
                    className="logo-icon logo-nba-lac"
                    style={{ width: '24px', height: '24px' }}
                  />{' '}
                  Intuit Dome
                </Link>
                , from{' '}
                <Link to="/stadium/hy-vee-arena">
                  <i
                    alt="Kansas City Scouts 1976 logo"
                    className="logo-icon logo-nhl-njd-1976"
                    style={{ width: '24px', height: '24px' }}
                  />{' '}
                  Kemper Arena
                </Link>{' '}
                to the{' '}
                <Link to="/stadium/prudential-center">
                  <i
                    alt="New Jersey Devils current logo"
                    className="logo-icon logo-nhl-njd"
                    style={{ width: '24px', height: '24px' }}
                  />{' '}
                  Prudential Center
                </Link>
                , if you ever saw your favorite team play there, we'll track it
                for you.
              </Typography>
            )}
            <Flags authorizedFlags={['accountActions']}>
              {isStadiumsLoading ? (
                <Skeleton animation="wave" width="100%" height="48px" />
              ) : account ? (
                <Button
                  onClick={() => navigate('/profile')}
                  variant="contained"
                >
                  View your progress!
                </Button>
              ) : (
                <Button
                  onClick={() => navigate('/account/sign-up')}
                  variant="contained"
                >
                  Get started tracking today!
                </Button>
              )}
            </Flags>
          </Grid>
          <Grid item xs={12} md={8}>
            <Map stadiums={stadiums} type="multiple" />
          </Grid>
        </Grid>
      </Grid>
      <Flags authorizedFlags={['competeWithFriends']}>
        <Grid item xs={12}>
          <Container max="xl" disableGutters>
            <Grid container spacing={8}>
              <Grid item xs={0} md={2}>
                {/* This is for spacing only */}
              </Grid>
              <Grid item xs={12} md={4}>
                <Image
                  alt="stadiums.me logo"
                  src={
                    isMobile
                      ? '/logos/logo-primary-rectangle.png'
                      : '/logos/logo-primary.png'
                  }
                  shift="right"
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Typography
                  component="h2"
                  variant="h4"
                  sx={{ textTransform: 'uppercase' }}
                  gutterBottom
                >
                  Compete with friends
                </Typography>
                <Typography component="h2" variant="h6" gutterBottom>
                  Dreamed of visiting every stadium out there?
                </Typography>
                <Typography paragraph>
                  Stadiums.me helps you track your visits to every MLB, NBA,
                  NFL, and NHL teams' stadiums, both past & present.
                </Typography>
                <Typography paragraph>
                  Keep track of your own visits, and compete with friends to see
                  who can visit the most.
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Flags>
    </Grid>
  );
}

export default Home;
