import React, { Fragment } from 'react';
// mui imports
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
// local imports
import Hero from '@components/shared/Hero';
import PageTitle from '@components/shared/PageTitle';

function Privacy() {
  return (
    <Fragment>
      <PageTitle title="Privacy Policy" />
      <Hero title="Privacy Policy" />
      <Container max="lg" disableGutters>
        <Typography paragraph>Privacy Policy, coming soon.</Typography>
      </Container>
    </Fragment>
  );
}

export default Privacy;
