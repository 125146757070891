import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { stadiumType } from '@helpers/types';
// mui imports
import makeStyles from '@mui/styles/makeStyles';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Skeleton from '@mui/material/Skeleton';
import Snackbar from '@mui/material/Snackbar';
import AddIcon from '@mui/icons-material/Add';
// local imports
import { useAuth } from '@providers/AuthProvider';
import { getVisits } from 'client/api';
import AddVisitModal from './AddVisitModal';

// TODO: replace makeStyles
const useStyles = makeStyles((theme) => ({
  loadingIcon: {
    margin: '12px',
  },
}));

export default function VisitsIcon({ stadium, venueId }) {
  const { account, isLoading: isAuthLoading } = useAuth();
  const classes = useStyles();

  // TODO: SET MODALS
  // add visit modal
  // opened / closed state for AddVisitModal
  const [isAddVisitModalOpen, setIsAddVisitModalOpen] = useState(false);
  // handles AddVisitModal opening
  const handleAddVisitModalOpen = () => {
    setIsAddVisitModalOpen(true);
  };
  // handles AddVisitModal closing
  const handleAddVisitModalClose = () => {
    setIsAddVisitModalOpen(false);
  };

  // visits
  const [visits, setVisits] = useState(null);
  const [visitsData, setVisitsData] = useState(null);
  const [isVisitsLoading, setIsVisitsLoading] = useState(true);
  const [visitsError, setVisitsError] = useState(null);

  useEffect(() => {
    if (account && venueId) {
      getVisits({
        accountId: account.id,
        venueId,
      })
        .then((response) => {
          setVisitsData(response.data);
          setVisits(response.data.visits);
          setIsVisitsLoading(false);
        })
        .catch((error) => {
          console.error(error); // TODO: error messaging
          setVisitsError(error);
          setIsVisitsLoading(false);
        });
    }
  }, [account, venueId]);

  const hasVisited = visits?.length > 0;

  // check all loading status together to make sure everything is ready
  const isAnyLoading = isVisitsLoading || isAuthLoading;
  return (
    <>
      {isAnyLoading ? (
        <Skeleton
          animation="wave"
          variant="circular"
          className={classes.loadingIcon}
          width={24}
          height={24}
        />
      ) : (
        <Tooltip
          title={`Add ${hasVisited ? 'another' : 'your first'} visit`}
          arrow
        >
          <IconButton
            aria-label="Add visit"
            onClick={handleAddVisitModalOpen}
            size="large"
          >
            <AddIcon color={hasVisited ? 'secondary' : 'default'} />
          </IconButton>
        </Tooltip>
      )}
      <AddVisitModal
        handleDialogClose={handleAddVisitModalClose}
        handleOpen={handleAddVisitModalOpen}
        isAddVisitModalOpen={isAddVisitModalOpen}
        stadium={stadium}
      />
    </>
  );
}

VisitsIcon.defaultProps = {
  isVisitsLoading: true,
  stadium: null,
  venueId: null,
  visits: null,
};

VisitsIcon.propTypes = {
  stadium: stadiumType,
  venueId: PropTypes.string,
};
