import React from 'react';
import PropTypes from 'prop-types';
// npm imports
import { Image } from 'mui-image';
// mui imports
import Skeleton from '@mui/material/Skeleton';

export default function StadiumImage({ isLoading, imgSrc }) {
  return (
    <Image
      alt="stadium image"
      shift="bottom"
      showLoading={
        <Skeleton
          sx={{ paddingTop: '56.25%' }}
          variant="rectangular"
          width="100%"
        />
      }
      src={imgSrc}
    />
  );
}

StadiumImage.defaultProps = {
  imgSrc: null,
  isLoading: false,
};

StadiumImage.propTypes = {
  imgSrc: PropTypes.string,
  isLoading: PropTypes.bool,
};
