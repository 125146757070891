import React from 'react';
// npm imports
import { BrowserRouter as Router } from 'react-router-dom';
// mui imports
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
// local imports
import AppLayout from '@layouts/AppLayout';
import { AuthProvider } from '@providers/AuthProvider';
import { FlagsProvider, flags } from '@providers/FlagsProvider';
import theme from '@styles/theme';

export default function App() {
  return (
    <AuthProvider>
      <FlagsProvider flags={flags}>
        <Router>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <AppLayout />
              </LocalizationProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </Router>
      </FlagsProvider>
    </AuthProvider>
  );
}
