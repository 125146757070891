import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { franchiseType } from '@helpers/types';
// npm imports
import { useNavigate } from 'react-router-dom';
// mui imports
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Skeleton from '@mui/material/Skeleton';
import ChevronRight from '@mui/icons-material/ChevronRight';

function TeamStadiumsListItem({ isHistoricalStadium, isLoading, stadium }) {
  const navigate = useNavigate();

  const occupancyReason = isHistoricalStadium
    ? stadium?.stints?.filter((team) => team.typeReason !== null)
    : stadium.typeReason !== null;

  const hasOccupancyReason = occupancyReason && occupancyReason.length >= 1;

  const handleLinkClick = () => {
    navigate(`/stadium/${stadium?.venueSlug}`);
  };

  // // split tenancy into multiple ranges, if applicable
  const tenancyDates = stadium?.stints?.map((team) => {
    const hasTeamOccupanyReason = team.typeReason !== null;
    if (team.start === team.end) {
      return `${team.start}${hasTeamOccupanyReason ? '*' : ''}`;
    } else {
      return `${team.start} - ${team.end}${hasTeamOccupanyReason ? '*' : ''}`;
    }
  });
  // reverse list, as newer teams are listed first by default
  tenancyDates?.reverse();

  return (
    <Fragment>
      <ListItem onClick={handleLinkClick} button={!isLoading}>
        <ListItemText
          primary={
            isLoading ? (
              <Skeleton animation="wave" width={155} />
            ) : (
              stadium?.venueName
            )
          }
          secondary={
            isLoading ? (
              <Skeleton animation="wave" width={105} />
            ) : stadium.occupancyStatus === 'historical' ? (
              tenancyDates.join(', ')
            ) : stadium.occupancyStatus === 'future' ? (
              `Starting in ${stadium.start}${hasOccupancyReason ? '*' : ''}`
            ) : (
              `Since ${stadium.start}${hasOccupancyReason ? '*' : ''}`
            )
          }
        />
        {isLoading ? (
          <Skeleton animation="wave" width={24} />
        ) : (
          <ChevronRight />
        )}
      </ListItem>
    </Fragment>
  );
}

TeamStadiumsListItem.defaultProps = {
  isHistoricalStadium: false,
  isLoading: false,
  stadium: {},
};

TeamStadiumsListItem.propTypes = {
  isHistoricalStadium: PropTypes.bool,
  isLoading: PropTypes.bool,
  stadium: franchiseType, // TODO: update this to be tenants, or update other to be franchise
};

export default TeamStadiumsListItem;
