import React from 'react';
import PropTypes from 'prop-types';
import { stadiumType } from '@helpers/types';
// mui imports
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
// local imports
import { Flags } from '@providers/FlagsProvider';
import Location from '@components/shared/Location';
import useWikipediaMainImage from 'client/hooks/useWikipediaMainImage';
import StadiumConstruction from './StadiumConstruction';
import StadiumEvents from './StadiumEvents';
import StadiumImage from './StadiumImage';
import StadiumLinks from './StadiumLinks';
import StadiumMap from './StadiumMap';
import StadiumNames from './StadiumNames';
import StadiumTenants from './StadiumTenants';
import VisitorCard from './VisitorCard'; // TODO

const useStyles = makeStyles((theme) => ({
  stadiumLocation: {
    textTransform: 'uppercase',
  },
}));

export default function Stadium({ isLoading, stadium }) {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints?.down('md'));
  const wikipediaLink = stadium?.links?.find(
    (link) => link.type === 'wikipedia'
  );
  const stadiumImageUrl = useWikipediaMainImage(wikipediaLink?.link);

  return (
    <Container max="lg" disableGutters>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography component="h1" variant="h3" align="left">
            {isLoading ? (
              <Skeleton animation="wave" width="25%" />
            ) : (
              stadium?.name
            )}
          </Typography>
          <Typography
            variant="overline"
            align="left"
            className={classes.stadiumLocation}
          >
            {isLoading ? (
              <Skeleton animation="wave" width="33%" />
            ) : (
              <Location location={stadium?.location} />
            )}
          </Typography>
        </Grid>
        <Flags authorizedFlags={['accountActions']}>
          <Grid item xs={12}>
            <VisitorCard
              isLoading={isLoading}
              stadium={stadium}
              venueId={stadium?.id}
            />
          </Grid>
        </Flags>
        <Grid item xs={12} md={4}>
          <Grid container spacing={2}>
            {isMobile && (
              <Grid item xs={12}>
                <StadiumImage isLoading={isLoading} imgSrc={stadiumImageUrl} />
              </Grid>
            )}
            <Grid item xs={12}>
              <StadiumMap isLoading={isLoading} stadium={stadium} />
            </Grid>
            <Grid item xs={12}>
              <StadiumTenants
                isLoading={isLoading}
                tenants={stadium?.tenants}
              />
            </Grid>
            <Grid item xs={12}>
              <StadiumNames isLoading={isLoading} names={stadium?.names} />
            </Grid>
            <Grid item xs={12}>
              <StadiumConstruction
                isLoading={isLoading}
                construction={stadium?.construction}
              />
            </Grid>
            <Grid item xs={12}>
              <StadiumLinks isLoading={isLoading} links={stadium?.links} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            {!isMobile && (
              <Grid item xs={12}>
                <StadiumImage isLoading={isLoading} imgSrc={stadiumImageUrl} />
              </Grid>
            )}
            <Flags authorizedFlags={['notableEvents']}>
              <Grid item xs={12}>
                <StadiumEvents isLoading={isLoading} events={stadium?.events} />
              </Grid>
            </Flags>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

Stadium.defaultProps = {
  isLoading: false,
  stadium: null,
};

Stadium.propTypes = {
  isLoading: PropTypes.bool,
  stadium: stadiumType,
};
