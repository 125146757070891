import React, { Fragment } from 'react';
// mui imports
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
// local imports
import Hero from '@components/shared/Hero';
import PageTitle from '@components/shared/PageTitle';

function Legal() {
  return (
    <Fragment>
      <PageTitle title="Legal" />
      <Hero title="Legal" />
      <Container max="lg" disableGutters>
        <Typography paragraph>
          Stadiums.me is an entertainment website. It should not be used as an
          official source for anything related to any of the leagues, teams, or
          venues represented. All names, logos, and likenesses are property of
          their respective owners and leagues.
        </Typography>
        <Typography paragraph>
          All data has been gathered from public sources under fair use
          guidelines.
        </Typography>
        <Typography paragraph>
          This website, its data, and all contents published here or related to
          this website via our API's, are copyrighted material owned by Code &
          Sons, and may not be re-distributed without permissions. Any and all
          commercial use is prohibited.
        </Typography>
      </Container>
    </Fragment>
  );
}

export default Legal;
