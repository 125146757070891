import { createTheme } from '@mui/material/styles';

const themeName = 'Ranch Water';
const navigation = { drawerWidth: 240 };
const palette = {
  mode: 'light',
  primary: {
    main: '#007aa5',
    light: '#52a9d7',
    dark: '#004e76',
  },
  secondary: {
    main: '#ffc107',
    light: '#fff350',
    dark: '#c79100',
  },
  black: {
    main: '#000000',
  },
  white: {
    main: '#ffffff',
  },
};

export default createTheme({ navigation, palette, themeName });
