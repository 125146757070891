import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { stadiumType } from '@helpers/types';
// mui imports
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Skeleton from '@mui/material/Skeleton';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
// local imports
import TeamStadiumsListItem from '@components/Team/TeamStadiumsListItem';
export default function TeamStadiumsList({ isLoading, stadiums }) {
  const [open, setOpen] = useState(false);

  const currentStadium = stadiums?.find(
    (stadium) => stadium.occupancyStatus === 'current'
  );

  const historicalStadiums = stadiums?.filter(
    (tenant) => tenant.occupancyStatus === 'historical'
  );

  const hasHistoricalStadiums = historicalStadiums?.length > 0;

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      {/* current stadium */}
      <List
        aria-labelledby="current-stadium"
        subheader={
          <ListSubheader id="current-stadium" disableGutters>
            {isLoading ? (
              <Skeleton animation="wave" width={95} />
            ) : (
              'Current stadium'
            )}
          </ListSubheader>
        }
      >
        <TeamStadiumsListItem isLoading={isLoading} stadium={currentStadium} />
      </List>
      {/* historical stadiums */}
      <List aria-labelledby="stadiums-historical-list">
        {hasHistoricalStadiums ? (
          <>
            <ListItem onClick={handleClick} button={!isLoading}>
              <ListItemText
                secondary={
                  isLoading ? (
                    <Skeleton animation="wave" width={155} />
                  ) : (
                    `See historical stadiums`
                  )
                }
              />
              {!isLoading && hasHistoricalStadiums ? (
                open ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )
              ) : (
                ''
              )}
            </ListItem>
            <Collapse
              in={open}
              timeout="auto"
              unmountOnExit
              // className={classes.nested}
            >
              <List>
                {historicalStadiums.map((stadium) => (
                  <TeamStadiumsListItem
                    isHistoricalStadium={true}
                    isLoading={isLoading}
                    key={stadium?.venueId}
                    stadium={stadium}
                  />
                ))}
              </List>
            </Collapse>
          </>
        ) : (
          <ListItem>
            <ListItemText
              secondary={
                isLoading ? (
                  <Skeleton animation="wave" width={155} />
                ) : (
                  `Team has no historical stadiums`
                )
              }
            />
          </ListItem>
        )}
      </List>
    </>
  );
}

TeamStadiumsList.defaultProps = {
  isLoading: false,
  stadiums: [{ stadiumId: '1' }, { stadiumId: '2' }], // provide 2 blank objects to display Skeleton during loading, with id's to satisfy React keys
};

TeamStadiumsList.propTypes = {
  isLoading: PropTypes.bool,
  stadiums: PropTypes.arrayOf(stadiumType),
};
