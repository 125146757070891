import React from 'react';
// mui imports
import makeStyles from '@mui/styles/makeStyles';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles((theme) => ({
  image: {
    backgroundImage: 'url(/images/coors-field-background.jpg)', // TODO: move image to better server
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.mode === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  alreadyAuthorized: {
    padding: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export default function AlreadyAuthorized() {
  const classes = useStyles();

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Grid
          alignItems="stretch"
          container
          className={classes.alreadyAuthorized}
          spacing={4}
        >
          <Grid item xs={12}>
            <Grid container spacing={1} alignItems="center" direction="column">
              <Grid item xs={12}>
                <Avatar>
                  <Skeleton
                    animation="wave"
                    variant="circular"
                    className={classes.loadingIcon}
                  />
                </Avatar>
              </Grid>
              <Grid item xs={12}>
                <Typography align="center" component="h1" variant="h5">
                  <Skeleton animation="wave" width="300px" />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography align="center" p>
              <Skeleton animation="wave" width="300px" />
            </Typography>
            <Typography align="center" p>
              <Skeleton animation="wave" width="250px" />
            </Typography>
            <Typography align="center" p>
              <Skeleton animation="wave" width="200px" />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
