import React, { useState } from 'react';
//npm imports
import { useLocation, useNavigate } from 'react-router-dom';
import {
  uniqueNamesGenerator,
  adjectives,
  colors,
  animals,
} from 'unique-names-generator';
// mui imports
import makeStyles from '@mui/styles/makeStyles';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
// local imports
import { useAuth } from '@providers/AuthProvider';
import Link from '@components/shared/Link';
import AlreadyAuthorized from '@components/Account/Authorized/AlreadyAuthorized';
import LoadingAuth from '@components/Account/LoadingAuth';

// TODO: replace makeStyles
const useStyles = makeStyles((theme) => ({
  image: {
    backgroundImage: 'url(/images/coors-field-background.jpg)', // TODO: move image to better server
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.mode === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  signUpForm: {
    padding: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  disclaimer: {
    marginTop: theme.spacing(8),
  },
}));

export default function SignUp() {
  const auth = useAuth();
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  const [signUpData, setSignUpData] = useState({
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    username: '',
  });
  const [optInChecked, setOptInChecked] = useState(true);

  const [isAuthorizing, setIsAuthorizing] = useState(false);
  const [error, setError] = useState(null);

  const [emailErrorMessage, setEmailErrorMessage] = useState(null);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState(null);
  const [firstNameErrorMessage, setFirstNameErrorMessage] = useState(null);
  const [lastNameErrorMessage, setLastNameErrorMessage] = useState(null);

  const handleTextFieldChange = (e) => {
    const { id, value } = e.target;

    // reset error messages when errored field is focused
    if (id === 'email') {
      setEmailErrorMessage(null);
    }
    if (id === 'firstName') {
      setFirstNameErrorMessage(null);
    }
    if (id === 'lastName') {
      setLastNameErrorMessage(null);
    }
    if (id === 'password') {
      setPasswordErrorMessage(null);
    }

    // set value of field
    setSignUpData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleOptInSwitch = (e) => {
    const { checked } = e.target;
    setOptInChecked(checked);
  };

  const onSubmitForm = (e) => {
    e.preventDefault();

    setIsAuthorizing(true);

    // reset error messages when submitting
    setError(null);
    setEmailErrorMessage(null);
    setPasswordErrorMessage(null);
    setFirstNameErrorMessage(null);
    setLastNameErrorMessage(null);

    // basic email regex
    // TODO: move this into shared helper
    const emailRegex = new RegExp(
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
    );
    const isEmailValid = emailRegex.exec(signUpData?.email);

    const isAllDataValid =
      signUpData?.email.length !== 0 &&
      isEmailValid &&
      signUpData?.password.length >= 6 &&
      signUpData?.firstName.length !== 0 &&
      signUpData?.lastName.length !== 0;

    if (!isAllDataValid) {
      if (signUpData?.email.length === 0) {
        setEmailErrorMessage('Email address is required.');
      }
      if (!isEmailValid) {
        setEmailErrorMessage('Email address entered is not valid.');
      }
      if (signUpData?.password.length < 6) {
        setPasswordErrorMessage(
          'Password must be at least six (6) characters long.'
        );
      }
      if (signUpData?.firstName.length === 0) {
        setFirstNameErrorMessage('First name is required.');
      }
      if (signUpData?.lastName.length === 0) {
        setLastNameErrorMessage('Last name is required.');
      }

      setIsAuthorizing(false);
      return;
    }

    // generate a random username
    // this saves time in the sign up step
    // users can change the name immediately after sign up on their profile page
    const generatedUsername = uniqueNamesGenerator({
      dictionaries: [colors, adjectives, animals],
      separator: '',
      style: 'capital',
    }); // example: RedBigDonkey

    // send sign up to firebase
    auth
      .signUp(
        signUpData?.email,
        signUpData?.password,
        signUpData?.firstName,
        signUpData?.lastName,
        generatedUsername,
        optInChecked === true ? 1 : 0 // value saved as tinyint boolean in database
      )
      .then((userCredential) => {
        navigate('/profile', { replace: true });
        setIsAuthorizing(false);
      })
      .catch((error) => {
        setIsAuthorizing(false);

        // auth/email-already-in-use should be the only error message ever received from the server,
        // as the others should be caught on the front-end before being sent to firebase.
        if (error?.code === 'auth/email-already-in-use') {
          return setError({
            code: error?.code,
            severity: 'warning',
            message: `Your email is already registered.`,
          });
        }

        if (error?.code === 'auth/invalid-email') {
          return setError({
            code: error?.code,
            severity: 'error',
            message: 'The email address entered is not valid.',
          });
        }

        if (error?.code === 'auth/weak-password') {
          return setError({
            code: error?.code,
            severity: 'error',
            message: 'Password must be at least six (6) characters long.',
          });
        }

        // return a catch-all for all other errors
        return setError({
          code: error?.code,
          severity: 'error',
          message: 'There was an issue signing you up. Please try again later.',
        });
      });
  };

  if (auth?.isLoading) {
    return <LoadingAuth />;
  }

  if (auth?.user) {
    return <AlreadyAuthorized />;
  }

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Grid container className={classes.signUpForm} spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={1} alignItems="center" direction="column">
              <Grid item xs={12}>
                <Avatar>
                  <PersonAddIcon />
                </Avatar>
              </Grid>
              <Grid item xs={12}>
                <Typography component="h1" variant="h5">
                  Sign up
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <FormControl>
              <form onSubmit={onSubmitForm} noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="given-name"
                      autoFocus
                      disabled={isAuthorizing}
                      error={firstNameErrorMessage}
                      fullWidth
                      helperText={firstNameErrorMessage}
                      id="firstName"
                      label="First Name"
                      name="firstName"
                      onChange={handleTextFieldChange}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="family-name"
                      disabled={isAuthorizing}
                      error={lastNameErrorMessage}
                      fullWidth
                      helperText={lastNameErrorMessage}
                      id="lastName"
                      label="Last Name"
                      name="lastName"
                      onChange={handleTextFieldChange}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      autoComplete="email"
                      disabled={isAuthorizing}
                      error={emailErrorMessage}
                      fullWidth
                      helperText={emailErrorMessage}
                      id="email"
                      label="Email Address"
                      name="email"
                      onChange={handleTextFieldChange}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      autoComplete="new-password"
                      disabled={isAuthorizing}
                      error={passwordErrorMessage}
                      fullWidth
                      helperText={passwordErrorMessage}
                      id="password"
                      label="Password"
                      name="password"
                      onChange={handleTextFieldChange}
                      required
                      type="password"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Switch
                      onChange={handleOptInSwitch}
                      label="Allow stadiums.me to email me"
                      id="optIn"
                      name="optIn"
                      defaultChecked
                    />
                    <Typography component="span" variant="body2">
                      Allow stadiums.me to email me
                    </Typography>
                  </Grid>
                  {error && (
                    <Grid item xs={12}>
                      <Alert severity={error?.severity}>
                        {error?.message}{' '}
                        {error?.code === 'auth/email-already-in-use' ? (
                          <Link
                            to="/account/sign-in"
                            state={{ from: location?.pathname }}
                          >
                            Try signing in instead.
                          </Link>
                        ) : null}
                      </Alert>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <LoadingButton
                      color="primary"
                      fullWidth
                      loading={isAuthorizing}
                      type="submit"
                      variant="contained"
                    >
                      Sign Up
                    </LoadingButton>
                  </Grid>
                </Grid>
              </form>
            </FormControl>
          </Grid>
          <Grid item>
            <Link to="/account/sign-in" state={{ from: location?.pathname }}>
              Already have an account? Sign in
            </Link>
          </Grid>
          <Grid item className={classes.disclaimer}>
            <Typography variant="caption">
              By signing up, you agree to the{' '}
              <Link to="/legal/terms-of-use">Terms of Use</Link> and{' '}
              <Link to="/legal/privacy-policy">Privacy Policy</Link>, including{' '}
              <Link to="/legal/cookie-policy">Cookie use</Link>.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
