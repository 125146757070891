import React, { useEffect, useState } from 'react';
// npm imports
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
// mui imports
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import LockResetIcon from '@mui/icons-material/LockReset';
// local imports
import { useAuth } from '@providers/AuthProvider';
import Link from '@components/shared/Link';
import AlreadyAuthorized from '@components/Account/Authorized/AlreadyAuthorized';
import LoadingAuth from '@components/Account/LoadingAuth';

const useStyles = makeStyles((theme) => ({
  image: {
    backgroundImage: 'url(/images/coors-field-background.jpg)', // TODO: move image to better server
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.mode === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  resetPasswordForm: {
    padding: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export default function ResetPassword() {
  const auth = useAuth();
  const classes = useStyles();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const theme = useTheme();

  // data from firebase
  const email = searchParams.get('email');
  const mode = searchParams.get('mode');
  const oobCode = searchParams.get('oobCode');

  const [resetPasswordData, setResetPasswordData] = useState({
    password: '',
  });

  const [isAuthorizing, setIsAuthorizing] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [error, setError] = useState(null);

  const [passwordErrorMessage, setPasswordErrorMessage] = useState(null);

  const handleTextFieldChange = (e) => {
    const { id, value } = e.target;

    // reset error messages when errored field is focused
    if (id === 'password') {
      setPasswordErrorMessage(null);
    }

    setResetPasswordData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const onSubmitForm = (e) => {
    e.preventDefault();

    setIsAuthorizing(true);

    // reset error messages when submitting
    setPasswordErrorMessage(null);

    const isAllDataValid = resetPasswordData?.password.length >= 6;

    if (!isAllDataValid) {
      if (resetPasswordData?.password.length < 6) {
        setPasswordErrorMessage(
          'Password must be at least six (6) characters long.'
        );
      }

      setIsAuthorizing(false);
      return;
    }

    // TODO TODO TODO
    if (mode === 'resetPassword') {
      auth
        .confirmPasswordReset(oobCode, resetPasswordData?.password)
        .then((userCredential) => {
          console.log('userCred', userCredential);

          // set the same message for an error or for success
          // this helps to not give away our users information
          // to bad actors
          setIsAuthorizing(false);
          return setShowMessage(true);
        })
        .catch((error) => {
          // TODO: log error
          // set the same message for an error or for success
          // this helps to not give away our users information
          // to bad actors
          setIsAuthorizing(false);
          return setError({
            code: error?.code,
            severity: 'error',
            message:
              'The link to reset your password is either invalid, or has expired.',
          });
        });
    }
  };

  if (auth?.isLoading) {
    return <LoadingAuth />;
  }

  if (auth?.user) {
    return <AlreadyAuthorized />;
  }

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        {showMessage ? (
          <Grid container className={classes.resetPasswordForm} spacing={4}>
            <Grid item xs={12}>
              <Grid
                container
                spacing={1}
                alignItems="center"
                direction="column"
              >
                <Grid item xs={12}>
                  <Avatar sx={{ bgcolor: theme.palette.success.light }}>
                    <LockResetIcon />
                  </Avatar>
                </Grid>
                <Grid item xs={12}>
                  <Typography align="center" component="h1" variant="h5">
                    Your password has been reset
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography align="center" p>
                <Link
                  to="/account/sign-in"
                  state={{ from: location?.pathname }}
                >
                  Sign in to your account
                </Link>
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid container className={classes.resetPasswordForm} spacing={4}>
            <Grid item>
              <Grid
                container
                spacing={1}
                alignItems="center"
                direction="column"
              >
                <Grid item>
                  <Avatar>
                    <LockResetIcon />
                  </Avatar>
                </Grid>
                <Grid item>
                  <Typography component="h1" variant="h5">
                    Reset password
                  </Typography>
                </Grid>{' '}
                <Grid item>
                  You are resetting the password for <strong>{email}</strong>.
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <FormControl>
                <form onSubmit={onSubmitForm} noValidate>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        autoComplete="new-password"
                        disabled={isAuthorizing}
                        error={passwordErrorMessage}
                        fullWidth
                        helperText={passwordErrorMessage}
                        id="password"
                        label="Password"
                        name="password"
                        onChange={handleTextFieldChange}
                        required
                        type="password"
                      />
                    </Grid>
                    {error && (
                      <Grid item xs={12}>
                        <Alert severity={error?.severity}>
                          {error?.message}{' '}
                          <Link to="/account/forgot-password">
                            Send another password reset email.
                          </Link>
                        </Alert>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <LoadingButton
                        color="primary"
                        fullWidth
                        loading={isAuthorizing}
                        type="submit"
                        variant="contained"
                      >
                        Reset password
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </form>
              </FormControl>
            </Grid>
            <Grid item>
              Don't want to reset your password?{' '}
              <Link to="/">Head to the homepage</Link>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
