import callAPI from './callAPI';

/**
 * @name getStadium
 * @description get a single stadium
 * @param {string} stadiumSlug slug of the stadium
 * @returns {object} response object
 */
export const getStadium = ({ stadiumSlug }) => {
  return callAPI({ url: `/stadiums/${stadiumSlug}` });
};
