import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
// npm imports
import { NavLink, useNavigate } from 'react-router-dom';
// mui imports
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import GavelIcon from '@mui/icons-material/Gavel';
import GroupsIcon from '@mui/icons-material/Groups';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import MailIcon from '@mui/icons-material/Mail';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import RestoreIcon from '@mui/icons-material/Restore';
import RoomIcon from '@mui/icons-material/Room';
import SportsBaseballIcon from '@mui/icons-material/SportsBaseball';
import SportsBasketballIcon from '@mui/icons-material/SportsBasketball';
import SportsFootballIcon from '@mui/icons-material/SportsFootball';
import SportsHockeyIcon from '@mui/icons-material/SportsHockey';
import UpdateIcon from '@mui/icons-material/Update';
// local imports
import { Flags } from '@providers/FlagsProvider';

// TODO: replace makeStyles
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: theme.navigation.drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: theme.navigation.drawerWidth + 1, // +1 to accommodate the border and hide scrollbar
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'space-between',
  },
  activeNav: {
    backgroundColor: theme.palette.secondary.main,
    fontWeight: 'bold',
  },
  linkDrawer: {
    position: 'absolute',
    bottom: 0,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

function NavigationDrawer({ handleDrawerClose, isDrawerOpen }) {
  const classes = useStyles();
  const theme = useTheme();

  const [bottomNavValue, setBottomNavValue] = useState();
  const navigate = useNavigate();

  const leagueMenus = [
    {
      name: 'mlb',
      icon: <SportsBaseballIcon />,
    },
    {
      name: 'nba',
      icon: <SportsBasketballIcon />,
    },
    {
      name: 'nfl',
      icon: <SportsFootballIcon />,
    },
    {
      name: 'nhl',
      icon: <SportsHockeyIcon />,
    },
  ];

  const [sportMenuOpen, setSportMenuOpen] = useState({
    mlb: false,
    nba: false,
    nfl: false,
    nhl: false,
  });

  const handleClick = (sport) => {
    const updatedState = !sportMenuOpen[sport];
    setSportMenuOpen({ ...sportMenuOpen, [sport]: updatedState });
  };

  const isMobile = useMediaQuery((theme) => theme.breakpoints?.down('md'));
  return (
    <Drawer
      anchor="left"
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
      open={isDrawerOpen}
      variant="persistent"
    >
      <div className={classes.drawerHeader}>
        <Tooltip title="Close navigation menu">
          <IconButton onClick={handleDrawerClose} size="large">
            <MenuOpenIcon />
          </IconButton>
        </Tooltip>
        <img
          alt="stadiums.me logo"
          height="48"
          width="48"
          src="/logos/icon-primary.png"
        />
      </div>
      <Divider />
      <List>
        <ListItem
          button
          component={NavLink}
          key="Home"
          end
          onClick={isMobile ? handleDrawerClose : null}
          style={({ isActive }) => ({
            backgroundColor: isActive ? theme.palette.secondary.main : '',
            fontWeight: isActive ? 'bold' : '',
          })}
          to="/"
        >
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
      </List>
      {isMobile && (
        <>
          <Divider />
          <Flags authorizedFlags={['accountActions']}>
            <ListItem
              button
              component={NavLink}
              key="Leaderboard"
              end
              onClick={isMobile ? handleDrawerClose : null}
              style={({ isActive }) => ({
                backgroundColor: isActive ? theme.palette.secondary.main : '',
                fontWeight: isActive ? 'bold' : '',
              })}
              to="/leaderboard"
            >
              <ListItemIcon>
                <LeaderboardIcon />
              </ListItemIcon>
              <ListItemText primary="Leaderboards" />
            </ListItem>
          </Flags>
        </>
      )}
      <Divider />
      {leagueMenus.map((league) => {
        return (
          <Fragment key={league.name}>
            <ListItem button onClick={() => handleClick(league.name)}>
              <ListItemIcon>{league.icon}</ListItemIcon>
              <ListItemText primary={league.name.toUpperCase()} />
              {sportMenuOpen[league.name] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse
              in={sportMenuOpen[league.name]}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                <ListItem
                  button
                  className={classes.nested}
                  component={NavLink}
                  end
                  onClick={() => {
                    setBottomNavValue(null);
                    return isMobile ? handleDrawerClose : null;
                  }}
                  style={({ isActive }) => ({
                    backgroundColor: isActive
                      ? theme.palette.secondary.main
                      : '',
                    fontWeight: isActive ? 'bold' : '',
                  })}
                  to={`/stadiums/${league.name}`}
                >
                  <ListItemIcon>
                    <RoomIcon />
                  </ListItemIcon>
                  <ListItemText primary="Current" />
                </ListItem>
                <ListItem
                  button
                  className={classes.nested}
                  component={NavLink}
                  end
                  onClick={() => {
                    setBottomNavValue(null);
                    return isMobile ? handleDrawerClose : null;
                  }}
                  style={({ isActive }) => ({
                    backgroundColor: isActive
                      ? theme.palette.secondary.main
                      : '',
                    fontWeight: isActive ? 'bold' : '',
                  })}
                  to={`/stadiums/${league.name}/historical`}
                >
                  <ListItemIcon>
                    <RestoreIcon />
                  </ListItemIcon>
                  <ListItemText primary="Historical" />
                </ListItem>
                <ListItem
                  button
                  className={classes.nested}
                  component={NavLink}
                  end
                  onClick={() => {
                    setBottomNavValue(null);
                    return isMobile ? handleDrawerClose : null;
                  }}
                  style={({ isActive }) => ({
                    backgroundColor: isActive
                      ? theme.palette.secondary.main
                      : '',
                    fontWeight: isActive ? 'bold' : '',
                  })}
                  to={`/stadiums/${league.name}/future`}
                >
                  <ListItemIcon>
                    <UpdateIcon />
                  </ListItemIcon>
                  <ListItemText primary="Future" />
                </ListItem>
                <Flags authorizedFlags={['browseByTeam']}>
                  <ListItem
                    button
                    className={classes.nested}
                    component={NavLink}
                    end
                    onClick={() => {
                      setBottomNavValue(null);
                      return isMobile ? handleDrawerClose : null;
                    }}
                    style={({ isActive }) => ({
                      backgroundColor: isActive
                        ? theme.palette.secondary.main
                        : '',
                      fontWeight: isActive ? 'bold' : '',
                    })}
                    to={`/teams/${league.name}`}
                  >
                    <ListItemIcon>
                      <GroupsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Teams" />
                  </ListItem>
                </Flags>
              </List>
            </Collapse>
          </Fragment>
        );
      })}
      <BottomNavigation
        className={classes.linkDrawer}
        onChange={(event, newValue) => {
          setBottomNavValue(newValue);
          if (isMobile) {
            handleDrawerClose();
          }
        }}
        showLabels
        value={bottomNavValue}
      >
        <BottomNavigationAction
          icon={<InfoIcon />}
          label="About"
          onClick={() => navigate('/about')}
        />
        <BottomNavigationAction
          icon={<MailIcon />}
          label="Contact"
          onClick={() => navigate('/contact')}
        />
        <BottomNavigationAction
          icon={<GavelIcon />}
          label="Legal"
          onClick={() => navigate('/legal')}
        />
      </BottomNavigation>
    </Drawer>
  );
}

NavigationDrawer.propTypes = {
  handleDrawerClose: PropTypes.func.isRequired,
  isDrawerOpen: PropTypes.bool.isRequired,
};

export default NavigationDrawer;
