import React from 'react';
import PropTypes from 'prop-types';
import { teamType } from '@helpers/types';
// mui imports
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
// local imports
import TeamCard from '@components/Team/TeamCard';

function Teams({ isLoading, teams }) {
  return (
    <Container max="lg" disableGutters>
      <Grid container spacing={2}>
        {teams?.map((team) => (
          <Grid item key={team?.id} xs={12} sm={6} md={4}>
            <TeamCard isLoading={isLoading} team={team} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

Teams.defaultProps = {
  isLoading: false,
  teams: null,
};

Teams.propTypes = {
  isLoading: PropTypes.bool,
  teams: PropTypes.arrayOf(teamType),
};

export default Teams;
