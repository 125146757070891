import React from 'react';
import PropTypes from 'prop-types';
// npm imports
import clsx from 'clsx';
import { format } from 'date-fns';
import pluralize from 'pluralize';
// mui imports
// import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { grey } from '@mui/material/colors';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .visitsTable.unknownData': {
      fontStyle: 'italic',
      color: grey[500],
    },
  },
}));

export default function VisitsTable({ stadium, visits, visitsCount }) {
  const classes = useStyles();

  const columns = [
    {
      field: 'date',
      headerName: 'Date',
      editable: true,
      minWidth: 175,
      maxWidth: 175,
      width: 175,
      valueFormatter: (params) =>
        params.value !== 'Unknown'
          ? format(new Date(params.value), 'MMMM d, yyyy')
          : params.value,
      cellClassName: (params) =>
        clsx('visitsTable visitsTableDate', {
          unknownData: params.value === 'Unknown',
        }),
    },
    {
      field: 'event',
      headerName: 'Event',
      width: 150,
      editable: true,
      cellClassName: (params) =>
        clsx('visitsTable visitsTableEvent', {
          unknownData: params.value === 'Unknown',
        }),
    },

    {
      field: 'seat',
      headerName: 'Seats',
      width: 275,
      editable: true,
      cellClassName: (params, classes) =>
        clsx('visitsTable visitsTableSeats', {
          unknownData: params.value === 'Unknown',
        }),
    },
  ];

  const rows = visits.map((visit) => {
    const seat =
      visit.section || visit.row || visit.seat
        ? `Section ${visit.section || 'Unknown'}, Row ${
            visit.row || 'Unknown'
          }, Seat ${visit.seat || 'Unknown'}`
        : 'Unknown';

    return {
      id: visit.id,
      date: visit.date || 'Unknown',
      event: visit.event || 'Unknown',
      seat,
    };
  });

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography component="h5">
          <em>
            You've visited {stadium?.name}{' '}
            {pluralize('time', visitsCount, true)}
          </em>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          autoHeight
          className={classes.root}
          density="compact"
          rows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          // checkboxSelection
          // disableSelectionOnClick
        />
      </Grid>
    </Grid>
  );
  // return <DataGrid rows={rows} columns={columns} pseatSize={10} />;
}

VisitsTable.defaultProps = {
  stadium: {},
  visits: [],
  visitsCount: 0,
};

VisitsTable.propTypes = {
  stadium: PropTypes.shape({
    name: PropTypes.string,
  }),
  visits: PropTypes.arrayOf(PropTypes.shape({})),
  visitsCount: PropTypes.number,
};
