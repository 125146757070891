import callAPI from './callAPI';

/**
 * @name getIsFavorite
 * @description get boolean value of favorite status of a single stadium for a single user
 * @param {string} accountId ID of the account
 * @param {string} venueId ID of the venue
 * @returns {object} response object
 */
export const getIsFavorite = ({ accountId, venueId }) => {
  return callAPI({
    url: '/accounts/favorites/', // TODO: remove trailing slash once that's figure out
    params: { accountId, venueId },
  });
};
