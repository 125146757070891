import React, { createContext, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import 'firebase/auth';
import callAPI from 'client/api/callAPI';
import {
  getAuth,
  setPersistence,
  signInWithEmailAndPassword,
  browserSessionPersistence,
} from 'firebase/auth';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_BASEURL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_FIREBASE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Use existing firebase / initialize firebase app with credentials
!firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();

// Create authContext
const authContext = createContext();

/**
 * @name AuthProvider
 * @description Provider component that wraps your app and makes auth object available to any child component that calls useAuth().
 * @param {node} children
 * @returns {Provider} authContext provider
 */
export function AuthProvider({ children }) {
  const auth = useAuthProvider();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

/**
 * @name useAuth
 * @description Hook for child components to get the auth object and re-render when it changes.
 * @returns {object} auth object
 */
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state

/**
 * @name useAuthProvider
 * @description Provider hook that creates auth object and handles state
 * @returns {object} auth method functions and user object
 */
function useAuthProvider() {
  const [account, setAccount] = useState(null);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Wrap any Firebase methods we want to use making sure to save the user to state.
  const signIn = async (email, password, rememberMe) => {
    // local saves user login state on the machine until user logs out
    let persistence = 'LOCAL';
    if (rememberMe === false) {
      // session logs user out when tab / window is closed
      persistence = 'SESSION';
    }
    setIsLoading(true);
    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence[persistence])
      .then(() => {
        return firebase
          .auth()
          .signInWithEmailAndPassword(email, password)
          .then(async (response) => {
            // firebase sign in successful, fetch stadiums.me account
            // TODO: Add a log here that confirms user sign in
            const account = await callAPI({
              method: 'post',
              url: '/accounts/sign-in/',
              data: {
                firebaseId: response.user.uid,
              },
              skipAuth: true, // skipAuth needed when signing in
            });
            // TODO: Add a log here that confirms stadiums account sign in
            setAccount(account?.data);
            setUser(response?.user);
            setIsLoading(false);
            return response?.user;
          });
      });
  };

  const signUp = (email, password, firstName, lastName, username, optIn) => {
    setIsLoading(true);
    return firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(async (response) => {
        // firebase sign up successful, create stadiums.me account
        // TODO: Add a log here that confirms user sign up
        const account = await callAPI({
          method: 'post',
          url: '/accounts/sign-up/',
          data: {
            email: email,
            firebaseId: response.user.uid,
            firstName: firstName,
            lastName: lastName,
            username: username,
            optIn: optIn,
          },
          skipAuth: true, // skipAuth needed when signing in
        });
        // TODO: Add a log here that confirms stadiums account sign up
        setAccount(account?.data);
        setUser(response?.user);
        setIsLoading(false);
        return response?.user;
      });
  };

  const signOut = () => {
    setIsLoading(true);
    return firebase
      .auth()
      .signOut()
      .then(() => {
        // TODO: Add a log here that confirms sign out
        // TODO: possibly redirect
        setAccount(null);
        setUser(null);
        setIsLoading(false);
      });
  };

  // TODO: set up password reset email
  const sendPasswordResetEmail = (email) => {
    return firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        return true;
      });
  };

  // TODO: set up confirm password reset
  const confirmPasswordReset = (code, password) => {
    return firebase
      .auth()
      .confirmPasswordReset(code, password)
      .then(() => {
        return true;
      });
  };

  // Subscribe to user on mount.
  // Because this sets state in the callback,
  // it will cause any component that utilizes this hook
  // to re-render with the latest auth object.
  useEffect(() => {
    setIsLoading(true);

    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        setUser(user);

        // if account is available, set it, otherwise look it up
        if (account) {
          setAccount(account?.data);
        } else {
          const account = await callAPI({
            method: 'post',
            url: '/accounts/sign-in/',
            data: {
              firebaseId: user.uid,
            },
            skipAuth: true, // skipAuth needed when signing in
          });
          setAccount(account?.data);
        }
        setIsLoading(false);
      } else {
        setUser(null);
        setAccount(null);
        setIsLoading(false);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  // Return the user object and auth methods
  return {
    confirmPasswordReset,
    sendPasswordResetEmail,
    signIn,
    signOut,
    signUp,
    account,
    user,
    isLoading,
  };
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
