import React from 'react';
import PropTypes from 'prop-types';
// mui imports
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    marginBottom: theme.spacing(3),
    marginLeft: '-24px',
    marginRight: '-24px',
    marginTop: '-24px',
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(8, 0, 6),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(8, 3, 6), // add left-padding on mobile
    },
    backgroundImage: 'url(/logos/stadium-grid.png)',
    backgroundRepeat: 'repeat-x',
    backgroundPosition: 'center center',
    // backgroundSize: isMobile ? '50%' : 'auto',
  },
  heroText: {
    // backgroundColor: '#ffffff',
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1),
    // backgroundColor: 'rgba(255,255,255,0.5)',
  },
}));

function Hero({
  backgroundColor,
  backgroundImage,
  description,
  isLoading,
  textBackgroundColor,
  title,
  type,
  filter,
  league,
  stadiumCount,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme?.breakpoints?.down('md'));

  if (type === 'image') {
    return (
      <div
        style={{
          marginTop: '24px',
          marginBottom: '24px',
          height: isMobile ? '205px' : '410px',
          backgroundImage: 'url(/logos/stadium-grid.png)',
          backgroundRepeat: 'repeat-x',
          backgroundPosition: 'center center',
          backgroundSize: isMobile ? '50%' : 'auto',
        }}
      />
    );
  }

  if (type === 'stadium') {
    return (
      <div className={classes.heroContent}>
        <Container maxWidth="lg">
          <div className={classes.heroText}>
            {/* <div className={classes.heroText}> */}
            <Typography
              component="h1"
              variant="h2"
              align="left"
              gutterBottom
              className={classes.heroText}
            >
              {isLoading ? (
                <Skeleton animation="wave" width="50%" />
              ) : (
                `${
                  filter !== 'current'
                    ? `${filter.charAt(0).toUpperCase() + filter.slice(1)} `
                    : ''
                }${league.toUpperCase()} Stadiums`
              )}
            </Typography>
            <Typography variant="h5" className={classes.heroText} paragraph>
              {isLoading ? (
                <>
                  <Skeleton animation="wave" width="100%" />
                  <Skeleton animation="wave" width="33%" />
                </>
              ) : filter === 'historical' ? (
                `${stadiumCount} stadiums have previously been the home - temporarily or permanently - to at least one of the
              current ${league.toUpperCase()} franchises.`
              ) : filter === 'future' ? (
                `There are currently ${stadiumCount} stadiums in the planning or construction phase, or waiting for a new season to start to house an ${league.toUpperCase()} team. Stadiums in the planning phase may never actually be built.`
              ) : filter === 'current' ? (
                description
              ) : null}
            </Typography>
          </div>
        </Container>
      </div>
    );
  }

  return (
    <div className={classes.heroContent}>
      <Container maxWidth="lg" disableGutters>
        <Typography
          component="h1"
          variant="h2"
          align="left"
          gutterBottom
          className={classes.heroText}
        >
          {isLoading ? <Skeleton animation="wave" width="50%" /> : title}
        </Typography>
        {isLoading ? (
          <Typography variant="h5" className={classes.heroText} paragraph>
            <Skeleton animation="wave" width="100%" />
            <Skeleton animation="wave" width="33%" />
          </Typography>
        ) : (
          description && (
            <Typography variant="h5" className={classes.heroText} paragraph>
              {description}
            </Typography>
          )
        )}
      </Container>
    </div>
  );
}

Hero.defaultProps = {
  backgroundColor: null,
  backgroundImage: null,
  description: null,
  isLoading: false,
  textBackgroundColor: null,
  title: null,
  type: 'text',
};

Hero.propTypes = {
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.string,
  description: PropTypes.string,
  isLoading: PropTypes.bool,
  textBackgroundColor: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
};

export default Hero;
