import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { franchiseType } from '@helpers/types';
// mui imports
import makeStyles from '@mui/styles/makeStyles';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Skeleton from '@mui/material/Skeleton';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
// local imports
import TeamAvatar from '@components/Team/TeamAvatar';

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function TeamListItem({
  isHistoricalTeam,
  isLoading,
  franchise,
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const historicalTeamNames = franchise?.teams?.filter(
    (team) => (team.teamStatus = 'historical')
  );

  const hasHistoricalTeamNames =
    (!isLoading && isHistoricalTeam) ||
    (historicalTeamNames && historicalTeamNames.length > 1);

  const occupancyReason = franchise?.teams?.filter(
    (team) => team.typeReason !== null
  );

  const hasOccupancyReason = occupancyReason && occupancyReason.length >= 1;

  const handleClick = () => {
    setOpen(!open);
  };

  // split tenancy into multiple ranges, if applicable
  const tenancyDates = franchise?.teams?.map((team) => {
    const hasTeamOccupanyReason = team.typeReason !== null;
    if (team.start === team.end) {
      return `${team.start}${hasTeamOccupanyReason ? '*' : ''}`;
    } else {
      return `${team.start} - ${team.end}${hasTeamOccupanyReason ? '*' : ''}`;
    }
  });
  // reverse list, as newer teams are listed first by default
  tenancyDates?.reverse();

  return (
    <Fragment>
      <ListItem
        onClick={handleClick}
        button={!isLoading && hasHistoricalTeamNames}
      >
        <ListItemAvatar>
          <TeamAvatar
            isLoading={isLoading}
            team={{
              abbr: franchise.abbr,
              league: franchise.league,
              teamName: franchise.franchiseName,
              year: franchise.end,
            }}
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            isLoading ? (
              <Skeleton animation="wave" width={155} />
            ) : (
              franchise.franchiseName
            )
          }
          secondary={
            isLoading ? (
              <Skeleton animation="wave" width={105} />
            ) : franchise.occupancyStatus === 'historical' ? (
              tenancyDates.join(', ')
            ) : franchise.occupancyStatus === 'future' ? (
              `Starting in ${franchise.start}${hasOccupancyReason ? '*' : ''}`
            ) : (
              `Since ${franchise.start}${hasOccupancyReason ? '*' : ''}`
            )
          }
        />
        {!isLoading && hasHistoricalTeamNames ? (
          open ? (
            <ExpandLess />
          ) : (
            <ExpandMore />
          )
        ) : (
          ''
        )}
      </ListItem>
      {hasHistoricalTeamNames && (
        <Collapse
          in={open}
          timeout="auto"
          unmountOnExit
          className={classes.nested}
        >
          <List
            component="div"
            subheader={
              isHistoricalTeam && (
                <ListSubheader id="stadium-construction-info-subheader">
                  Known at the time as:
                </ListSubheader>
              )
            }
          >
            {historicalTeamNames.map((team) => (
              <ListItem dense key={team.tenantId} className={classes.nested}>
                <ListItemAvatar>
                  <TeamAvatar
                    isLoading={isLoading}
                    team={{
                      abbr: team.abbr,
                      league: team.league,
                      teamName: team.teamName,
                      year: team.end,
                    }}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    isLoading ? (
                      <Skeleton animation="wave" width={155} />
                    ) : (
                      team.teamName
                    )
                  }
                  secondary={
                    isLoading ? (
                      <Skeleton animation="wave" width={105} />
                    ) : team.typeReason ? (
                      `${team.start} ${
                        team.end && team.end !== team.start
                          ? `- ${team.end}`
                          : ''
                      } (${team.typeReason})`
                    ) : team.end === '0' || team.end === null ? ( // TODO: remove === 0 when done
                      `Since ${team.start}`
                    ) : team.start === team.end ? (
                      team.start
                    ) : (
                      `${team.start} - ${team.end}`
                    )
                  }
                />
              </ListItem>
            ))}
          </List>
        </Collapse>
      )}
    </Fragment>
  );
}

TeamListItem.defaultProps = {
  isHistoricalTeam: false,
  isLoading: false,
  franchise: {},
};

TeamListItem.propTypes = {
  franchise: franchiseType, // TODO: update this to be tenants, or update other to be franchise
  isHistoricalTeam: PropTypes.bool,
  isLoading: PropTypes.bool,
};
