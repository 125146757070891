import React, { useEffect } from 'react';
// npm imports
import { Route, Routes, useLocation } from 'react-router-dom';
// local imports
import Account from '@components/Account';
import ForgotPassword from '@components/Account/ForgotPassword';
import ResetPassword from '@components/Account/ResetPassword';
import SignIn from '@components/Account/SignIn';
import SignUp from '@components/Account/SignUp';
import Profile from '@components/Profile';
import ProfileBucketlists from '@components/Profile/ProfileBucketlists';
import ProfileFavorites from '@components/Profile/ProfileFavorites';
import Stadiums from '@components/Stadiums';
import About from '@pages/About';
import Contact from '@pages/Contact';
import Home from '@pages/Home';
import Legal from '@pages/Legal';
import Cookies from '@pages/Legal/Cookies';
import Leaderboard from '@pages/Leaderboard';
import Privacy from '@pages/Legal/Privacy';
import Terms from '@pages/Legal/Terms';
import NotFound from '@pages/NotFound';
import Stadium from '@pages/Stadium';
import Teams from '@pages/Teams';

function AppRoutes() {
  // scroll to top when route changes
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  return (
    <Routes>
      {/* Home */}
      <Route path="/" element={<Home />} />

      {/* /about */}
      <Route path="about" element={<About />} />

      {/* /contact */}
      <Route path="contact" element={<Contact />} />

      {/* /legal */}
      <Route path="legal" element={<Legal />} />
      <Route path="legal/cookie-policy" element={<Cookies />} />
      <Route path="legal/privacy-policy" element={<Privacy />} />
      <Route path="legal/terms-of-use" element={<Terms />} />

      {/* /account */}
      <Route path="account" element={<Account />} />
      <Route path="account/sign-in" element={<SignIn />} />
      <Route path="account/sign-up" element={<SignUp />} />
      <Route path="account/forgot-password" element={<ForgotPassword />} />
      <Route path="account/reset-password" element={<ResetPassword />} />

      {/* /profile */}
      <Route path="profile" element={<Profile />} />
      <Route path="profile/:profileSlug" element={<Profile />} />
      <Route path="profile/:profileSlug/visits" element={<Profile />} />
      <Route
        path="profile/:profileSlug/favorites"
        element={<ProfileFavorites />}
      />
      <Route
        path="profile/:profileSlug/bucketlist"
        element={<ProfileBucketlists />}
      />
      <Route path="profile/visits" element={<Profile />} />
      <Route path="profile/favorites" element={<ProfileFavorites />} />
      <Route path="profile/bucketlist" element={<ProfileBucketlists />} />

      {/* /stadium */}
      <Route path="stadium" element={<NotFound />} />
      <Route path="stadium/:stadiumSlug" element={<Stadium />} />

      {/* /leaderboard */}
      <Route path="leaderboard" element={<Leaderboard />} />

      {/* /stadiums */}
      <Route path="stadiums">
        <Route
          path="mlb"
          element={<Stadiums filter="current" league="mlb" status="current" />}
        />
        <Route
          path="mlb/historical"
          element={
            <Stadiums filter="historical" league="mlb" status="historical" />
          }
        />
        <Route
          path="mlb/future"
          element={<Stadiums filter="future" league="mlb" status="future" />}
        />
        <Route
          path="nba"
          element={<Stadiums filter="current" league="nba" status="current" />}
        />
        <Route
          path="nba/historical"
          element={
            <Stadiums filter="historical" league="nba" status="historical" />
          }
        />
        <Route
          path="nba/future"
          element={<Stadiums filter="future" league="nba" status="future" />}
        />
        <Route
          path="nfl"
          element={<Stadiums filter="current" league="nfl" status="current" />}
        />
        <Route
          path="nfl/historical"
          element={
            <Stadiums filter="historical" league="nfl" status="historical" />
          }
        />
        <Route
          path="nfl/future"
          element={<Stadiums filter="future" league="nfl" status="future" />}
        />
        <Route
          path="nhl"
          element={<Stadiums filter="current" league="nhl" status="current" />}
        />
        <Route
          path="nhl/historical"
          element={
            <Stadiums filter="historical" league="nhl" status="historical" />
          }
        />
        <Route
          path="nhl/future"
          element={<Stadiums filter="future" league="nhl" status="future" />}
        />
      </Route>

      {/* /teams */}
      <Route path="teams">
        <Route path="mlb" element={<Teams league="mlb" />} />
        <Route path="nba" element={<Teams league="nba" />} />
        <Route path="nfl" element={<Teams league="nfl" />} />
        <Route path="nhl" element={<Teams league="nhl" />} />
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default AppRoutes;
