import React from 'react';
import PropTypes from 'prop-types';
// mui imports
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Skeleton from '@mui/material/Skeleton';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';

const useStyles = makeStyles((theme) => ({
  // TODO: add cardHeader style to shared style file
  cardHeader: {
    background: theme.palette.secondary.main,
  },
}));

export default function StadiumNames({ isLoading, names }) {
  const classes = useStyles();

  const currentName = names?.find((name) => name.status === 'current');
  const futureNames = names?.filter((name) => name.status === 'future');
  const historicalNames = names?.filter((name) => name.status === 'historical');
  const planningNames = names?.filter((name) => name.status === 'planning');

  return (
    <Card>
      <CardHeader
        avatar={
          isLoading ? (
            <Skeleton
              animation="wave"
              variant="circular"
              width={24}
              height={24}
            />
          ) : (
            <RecordVoiceOverIcon />
          )
        }
        className={classes.cardHeader}
        title={
          isLoading ? <Skeleton animation="wave" width={65} /> : 'Known As'
        }
      />
      <CardContent>
        <List
          aria-labelledby="stadium-current-names-subheader"
          subheader={
            <ListSubheader id="stadium-current-names-subheader" disableGutters>
              {isLoading ? (
                <Skeleton animation="wave" width={85} />
              ) : currentName.end !== null ? (
                'Name at time of closure'
              ) : (
                'Current name'
              )}
            </ListSubheader>
          }
        >
          <ListItem>
            <ListItemText
              primary={
                isLoading ? (
                  <Skeleton animation="wave" width={165} />
                ) : (
                  currentName.name
                )
              }
              secondary={
                isLoading ? (
                  <Skeleton animation="wave" width={105} />
                ) : currentName.end !== null ? (
                  `${currentName.start} - ${currentName.end}`
                ) : (
                  `Since ${currentName.start}`
                )
              }
            />
          </ListItem>
        </List>
        {historicalNames && historicalNames.length > 0 && (
          <List
            aria-labelledby="stadium-historical-names-subheader"
            subheader={
              <ListSubheader
                id="stadium-historical-names-subheader"
                disableGutters
              >
                {isLoading ? (
                  <Skeleton animation="wave" width={95} />
                ) : (
                  'Historical names'
                )}
              </ListSubheader>
            }
          >
            {historicalNames.map((name) => {
              return (
                <ListItem key={name.id}>
                  <ListItemText
                    primary={
                      isLoading ? (
                        <Skeleton animation="wave" width={165} />
                      ) : (
                        name.name
                      )
                    }
                    secondary={
                      isLoading ? (
                        <Skeleton animation="wave" width={105} />
                      ) : name.start === name.end ? (
                        name.start
                      ) : (
                        `${name.start} - ${name.end}`
                      )
                    }
                  />
                </ListItem>
              );
            })}
          </List>
        )}
        {futureNames && futureNames.length > 0 && (
          <List
            aria-labelledby="stadium-future-names-subheader"
            subheader={
              <ListSubheader id="stadium-future-names-subheader" disableGutters>
                {isLoading ? (
                  <Skeleton animation="wave" width={85} />
                ) : (
                  'Future names'
                )}
              </ListSubheader>
            }
          >
            {futureNames.map((name) => {
              return (
                <ListItem key={name.id}>
                  <ListItemText
                    primary={
                      isLoading ? (
                        <Skeleton animation="wave" width={165} />
                      ) : (
                        name.name
                      )
                    }
                    secondary={
                      isLoading ? (
                        <Skeleton animation="wave" width={105} />
                      ) : name.start !== '0' ? (
                        `Starting in ${name.start}`
                      ) : (
                        'Coming soon'
                      )
                    }
                  />
                </ListItem>
              );
            })}
          </List>
        )}
        {planningNames && planningNames.length > 0 && (
          <List
            aria-labelledby="stadium-planning-names-subheader"
            subheader={
              <ListSubheader
                id="stadium-planning-names-subheader"
                disableGutters
              >
                {isLoading ? (
                  <Skeleton animation="wave" width={95} />
                ) : (
                  'Planning / Construction names'
                )}
              </ListSubheader>
            }
          >
            {planningNames.map((name) => {
              return (
                <ListItem key={name.id}>
                  <ListItemText
                    primary={
                      isLoading ? (
                        <Skeleton animation="wave" width={165} />
                      ) : (
                        name.name
                      )
                    }
                    secondary={
                      isLoading ? (
                        <Skeleton animation="wave" width={105} />
                      ) : name.start === name.end ? (
                        name.start
                      ) : (
                        `${name.start} - ${name.end}`
                      )
                    }
                  />
                </ListItem>
              );
            })}
          </List>
        )}
      </CardContent>
    </Card>
  );
}

StadiumNames.defaultProps = {
  isLoading: false,
  names: [{}, {}], // provide 2 blank objects to display Skeleton during loading
};

StadiumNames.propTypes = {
  isLoading: PropTypes.bool,
  names: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      end: PropTypes.string,
      name: PropTypes.string,
      start: PropTypes.string,
      status: PropTypes.string,
      venueId: PropTypes.string,
    })
  ),
};
