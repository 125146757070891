/**
 * @file contains helper constants used throughout the app
 */

/**
 * @name GLOBAL_DESCRIPTION
 * @description global description to use in <meta> tags
 */
export const GLOBAL_DESCRIPTION =
  "The easiest way to track which MLB, NBA, NFL and NHL stadiums you've visited.";

/**
 * @name GLOBAL_TITLE
 * @description global title to use in <title> tags
 */
export const GLOBAL_TITLE = 'stadiums.me';
