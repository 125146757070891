import React, { useState } from 'react';
// npm imports
import { useLocation, useNavigate } from 'react-router-dom';
// mui imports
import makeStyles from '@mui/styles/makeStyles';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import LoginIcon from '@mui/icons-material/Login';
// local imports
import { useAuth } from '@providers/AuthProvider';
import Link from '@components/shared/Link';
import AlreadyAuthorized from '@components/Account/Authorized/AlreadyAuthorized';
import LoadingAuth from '@components/Account/LoadingAuth';

const useStyles = makeStyles((theme) => ({
  image: {
    backgroundImage: 'url(/images/coors-field-background.jpg)', // TODO: move image to better server
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.mode === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  signUpForm: {
    padding: theme.spacing(8, 4), // TODO: spacing
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export default function SignIn() {
  const auth = useAuth();
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  const [signInData, setSignInData] = useState({
    email: '',
    password: '',
  });
  const [rememberMeChecked, setRememberMeChecked] = useState(true);

  const [isAuthorizing, setIsAuthorizing] = useState(false);
  const [error, setError] = useState(null);

  const [emailErrorMessage, setEmailErrorMessage] = useState(null);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState(null);

  const handleTextFieldChange = (e) => {
    const { id, value } = e.target;

    // reset error messages when errored field is focused
    if (id === 'email') {
      setEmailErrorMessage(null);
    }

    if (id === 'password') {
      setPasswordErrorMessage(null);
    }

    setSignInData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleRememberMeSwitch = (e) => {
    const { checked } = e.target;
    setRememberMeChecked(checked);
  };

  const onSubmitForm = (e) => {
    e.preventDefault();

    setIsAuthorizing(true);

    // reset error messages when submitting
    setError(null);
    setEmailErrorMessage(null);
    setPasswordErrorMessage(null);

    // basic email regex
    // TODO: move this into shared helper
    const emailRegex = new RegExp(
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
    );
    const isEmailValid = emailRegex.exec(signInData?.email);

    const isAllDataValid =
      signInData?.email.length !== 0 &&
      isEmailValid &&
      signInData?.password.length !== 0;

    if (!isAllDataValid) {
      if (signInData?.email.length === 0) {
        setEmailErrorMessage('Email address is required.');
      }
      if (!isEmailValid) {
        setEmailErrorMessage('Email address entered is not valid.');
      }
      if (signInData?.password.length === 0) {
        setPasswordErrorMessage('Password is required.');
      }

      setIsAuthorizing(false);
      return;
    }

    auth
      .signIn(signInData.email, signInData.password, rememberMeChecked)
      .then((userCredential) => {
        // TODO: set remember me status
        console.log('rememberMeChecked', rememberMeChecked);

        // if user came from a /account route,
        // most likely they were resetting their password
        // and they should not be redirected back to that page
        if (location?.state?.from?.includes('account')) {
          navigate('/');
        } else {
          navigate(-1);
        }
        setIsAuthorizing(false);
      })
      .catch((error) => {
        setIsAuthorizing(false);

        // return a catch-all for all errors
        // we don't want to be too specific to give away any info about
        // how our account information is saved
        return setError({
          code: error?.code,
          severity: 'error',
          message: 'Incorrect email or password.',
        });
      });
  };

  if (auth?.isLoading) {
    return <LoadingAuth />;
  }

  if (auth?.user) {
    return <AlreadyAuthorized />;
  }

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Grid container className={classes.signUpForm} spacing={4}>
          <Grid item>
            <Grid container spacing={1} alignItems="center" direction="column">
              <Grid item>
                <Avatar>
                  <LoginIcon />
                </Avatar>
              </Grid>
              <Grid item>
                <Typography component="h1" variant="h5">
                  Sign in
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <FormControl>
              <form onSubmit={onSubmitForm} noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      autoComplete="email"
                      autoFocus
                      disabled={isAuthorizing}
                      error={emailErrorMessage}
                      fullWidth
                      helperText={emailErrorMessage}
                      id="email"
                      label="Email Address"
                      name="email"
                      onChange={handleTextFieldChange}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      autoComplete="current-password"
                      disabled={isAuthorizing}
                      error={passwordErrorMessage}
                      fullWidth
                      helperText={passwordErrorMessage}
                      id="password"
                      label="Password"
                      name="password"
                      onChange={handleTextFieldChange}
                      required
                      type="password"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Switch
                      onChange={handleRememberMeSwitch}
                      label="Allow stadiums.me to email me"
                      id="optIn"
                      name="optIn"
                      defaultChecked
                    />
                    <Typography component="span" variant="body2">
                      Remember me
                    </Typography>
                  </Grid>
                  {error && (
                    <Grid item xs={12}>
                      <Alert severity={error?.severity}>
                        {error?.message}{' '}
                        <Link to="/account/forgot-password">
                          Reset your password.
                        </Link>
                      </Alert>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <LoadingButton
                      color="primary"
                      fullWidth
                      loading={isAuthorizing}
                      type="submit"
                      variant="contained"
                    >
                      Sign In
                    </LoadingButton>
                  </Grid>
                </Grid>
              </form>
            </FormControl>
          </Grid>
          <Grid item>
            <Link to="/account/forgot-password">
              Forgot your password? Reset it
            </Link>
          </Grid>
          <Grid item>
            <Link to="/account/sign-up">Don't have an account? Sign Up</Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
