import React from 'react';
// mui imports
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
// local imports
import Link from '@components/shared/Link';

function Error({ error }) {
  console.log('error', error);

  return (
    <Grid
      container
      alignItems="center"
      direction="column"
      justifyContent="center"
      sx={{ padding: (theme) => theme.spacing(10) }}
    >
      <Grid item xs={12}>
        <Typography variant="h2" component="h1">
          Uh oh.
        </Typography>
        <Divider sx={{ margin: (theme) => theme.spacing(4, 0) }} />
        <Typography paragraph>
          We seem to be experiencing an issue loading this data. Please try
          again. If you continue to experience issues, please{' '}
          <Link to="/contact">contact us.</Link>
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Error;
