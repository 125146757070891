import countries from 'country-code-lookup';
import provinces from 'provinces';

/**
 * @file contains helper functions used throughout the app
 */

/**
 * @name stadiumLocation
 * @description returns formatted city, region, country from a loocation objectconverts database (MySQL / PHP) dates into a javascript version for parsing
 * @param {object} location object containing location information for a stadium
 * @param {boolean} showCountry should country be displayed
 * @returns {string} location string
 */

export function stadiumLocation({ location = {}, showCountry = true }) {
  // France is not part of `provinces` package, create special return
  if (location?.country === 'FR') {
    return `${location?.city}, ${countries?.byIso(location?.country)?.country}`;
  }

  // Puerto Rico is not part of `provinces` package, create special return
  if (location?.country === 'PR') {
    return `${location?.city}, ${countries?.byIso(location?.country)?.country}`;
  }

  const city = location?.city;
  const province = provinces?.find(
    (prv) =>
      prv?.country === location?.country && prv?.short === location?.region
  );
  const country = countries.byIso(location?.country);

  return `${city}, ${province?.name} ${
    showCountry ? `• ${country?.country}` : ''
  }`;
}
