import React from 'react';
import PropTypes from 'prop-types';
import { stadiumType } from '@helpers/types';
// npm imports
import { useNavigate } from 'react-router-dom';
// mui imports
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
// local imports
import useWikipediaMainImage from '@hooks/useWikipediaMainImage';
import TenantsList from '@components/Tenants/TenantsList';
import { useAuth } from '@providers/AuthProvider';
import Location from '@components/shared/Location';
import BucketlistsIcon from '../VisitorCard/BucketlistsIcon';
import FavoritesIcon from '../VisitorCard/FavoritesIcon';
import VisitsIcon from '../VisitorCard/VisitsIcon';

const useStyles = makeStyles((theme) => ({
  cardMedia: {
    paddingTop: '56.25%', // 16:9
    cursor: 'pointer',
  },
  displayAsLink: {
    cursor: 'pointer',
  },
}));

export default function StadiumCard({
  isLoading,
  filter = 'current',
  stadium,
}) {
  const { account } = useAuth();
  const classes = useStyles();

  const navigate = useNavigate();
  const handleOnClick = (route) => navigate(route);

  const wikipediaLink = stadium?.links?.find(
    (link) => link.type === 'wikipedia'
  );
  const imageUrl =
    useWikipediaMainImage(wikipediaLink?.link) || '/logos/icon-primary.svg';

  return (
    <Card className={classes.root}>
      <CardHeader
        className={classes.displayAsLink}
        onClick={() => handleOnClick(`/stadium/${stadium?.slug}`)}
        // TODO: figure out why styles are being overridden here
        title={
          isLoading ? (
            <Skeleton animation="wave" width={195} />
          ) : (
            <Typography variant={'h6'}>{stadium?.name}</Typography>
          )
        }
        subheader={
          isLoading ? (
            <Skeleton animation="wave" width={140} />
          ) : (
            <Typography variant="overline">
              <Location location={stadium?.location} showCountry={false} />
            </Typography>
          )
        }
      />
      {isLoading ? (
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={classes.cardMedia}
        />
      ) : (
        <CardMedia
          className={classes.cardMedia}
          image={imageUrl}
          onClick={() => handleOnClick(`/stadium/${stadium?.slug}`)}
          title={stadium?.name}
        />
      )}
      <CardContent>
        <TenantsList isLoading={isLoading} tenants={stadium?.tenants} />
      </CardContent>
      {account ? (
        <CardActions disableSpacing>
          <VisitsIcon stadium={stadium} venueId={stadium?.id} />
          <BucketlistsIcon stadium={stadium} venueId={stadium?.id} />
          <FavoritesIcon stadium={stadium} venueId={stadium?.id} />
        </CardActions>
      ) : null}
    </Card>
  );
}

StadiumCard.defaultProps = {
  isLoading: false,
  filter: null,
  stadium: null,
};

StadiumCard.propTypes = {
  isLoading: PropTypes.bool,
  filter: PropTypes.string,
  stadium: stadiumType,
};
