import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
// npm imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWikipediaW } from '@fortawesome/free-brands-svg-icons';
// mui imports
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import CardHeader from '@mui/material/CardHeader';
import LanguageIcon from '@mui/icons-material/Language';
import Tooltip from '@mui/material/Tooltip';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import LinkIcon from '@mui/icons-material/Link';
import TwitterIcon from '@mui/icons-material/Twitter';

const useStyles = makeStyles((theme) => ({
  // TODO: add cardHeader style to shared style file
  cardHeader: {
    background: theme.palette.secondary.main,
  },
}));

export default function StadiumLinks({ isLoading = false, links }) {
  const classes = useStyles();

  const linkList = () => {
    let icon;

    if (links && links.length) {
      return links.map((link, index) => {
        if (link.type === 'official') {
          icon = <LanguageIcon />;
        }
        if (link.type === 'wikipedia') {
          icon = <FontAwesomeIcon icon={faWikipediaW} fixedWidth />;
        }
        if (link.type === 'twitter') {
          icon = <TwitterIcon />;
        }

        return (
          <Tooltip title={link.title} key={index} arrow>
            <IconButton
              aria-label={`${link.title} link`}
              href={link.link}
              target="_blank"
              size="large"
            >
              {icon}
            </IconButton>
          </Tooltip>
        );
      });
    }

    return (
      <Typography variant="body2">
        Sorry, we can't find any links for this stadium at this time.
      </Typography>
    );
  };

  return (
    <Card>
      <CardHeader
        avatar={
          isLoading ? (
            <Skeleton
              animation="wave"
              variant="circular"
              width={24}
              height={24}
            />
          ) : (
            <LinkIcon />
          )
        }
        className={classes.cardHeader}
        title={isLoading ? <Skeleton animation="wave" width={50} /> : 'Links'}
      />
      <CardActions>
        {isLoading ? (
          <Fragment>
            <IconButton size="large">
              <Skeleton
                animation="wave"
                variant="circular"
                width={24}
                height={24}
              />
            </IconButton>
            <IconButton size="large">
              <Skeleton
                animation="wave"
                variant="circular"
                width={24}
                height={24}
              />
            </IconButton>
            <IconButton size="large">
              <Skeleton
                animation="wave"
                variant="circular"
                width={24}
                height={24}
              />
            </IconButton>
          </Fragment>
        ) : (
          <Fragment>{linkList()}</Fragment>
        )}
      </CardActions>
    </Card>
  );
}

StadiumLinks.defaultProps = {
  isLoading: false,
  links: [],
};

StadiumLinks.propTypes = {
  isLoading: PropTypes.bool,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      title: PropTypes.string,
      type: PropTypes.string,
    })
  ),
};
