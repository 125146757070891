import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { stadiumType } from '@helpers/types';
// npm imports
// mui imports
import makeStyles from '@mui/styles/makeStyles';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Skeleton from '@mui/material/Skeleton';
import Snackbar from '@mui/material/Snackbar';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
// local imports
import { useAuth } from '@providers/AuthProvider';
import { addBucketlist, getIsOnBucketlist, deleteBucketlist } from 'client/api';

const useStyles = makeStyles((theme) => ({
  loadingIcon: {
    margin: '12px',
  },
}));

export default function BucketlistsIcon({ stadium, venueId }) {
  const { account, isLoading: isAuthLoading } = useAuth();
  const classes = useStyles();

  const [isOnBucketlist, setisOnBucketlist] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // after success
  // opened / closed state for Success toast
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  // handles Success toast closing
  const handleSuccessClose = () => {
    setIsSuccessOpen(false);
  };

  // after error
  // opened / closed state for Error toast
  const [isErrorOpen, setIsErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  // handles Success toast closing
  const handleErrorClose = () => {
    setIsErrorOpen(false);
  };

  const handleBucketlistToggle = () => {
    if (isOnBucketlist) {
      deleteBucketlist({
        accountId: account.id,
        venueId,
        isOnBucketlist: !isOnBucketlist,
      })
        .then((response) => {
          setIsLoading(false);
          setisOnBucketlist(!isOnBucketlist);
          setSuccessMessage('removed from bucketlist');
          setIsSuccessOpen(true);
        })
        .catch((error) => {
          setIsLoading(false);
          console.error(error); // TODO: error messaging
          setErrorMessage(
            'Something went wrong, and your bucketlist was not updated. Please refresh and try again.'
          );
          setIsErrorOpen(true);
        });
    } else {
      addBucketlist({
        accountId: account.id,
        venueId,
        isOnBucketlist: !isOnBucketlist,
      })
        .then((response) => {
          setIsLoading(false);
          setisOnBucketlist(!isOnBucketlist);
          setSuccessMessage('saved to your bucketlist');
          setIsSuccessOpen(true);
        })
        .catch((error) => {
          setIsLoading(false);
          console.error(error); // TODO: error messaging
          // error 400: not all fields provided or bad data
          setErrorMessage(
            'Something went wrong, and your bucketlist was not updated. Please refresh and try again.'
          );
          setIsErrorOpen(true);
        });
    }
  };

  useEffect(() => {
    if (account && venueId) {
      getIsOnBucketlist({
        accountId: account.id,
        venueId,
      })
        .then((response) => {
          setisOnBucketlist(response.data.isOnBucketlist);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error); // TODO: error messaging
          setIsLoading(false);
        });
    }
  }, [account, venueId]);

  // check all loading status together to make sure everything is ready
  const isAnyLoading = isLoading || isAuthLoading;
  return (
    <>
      {isAnyLoading ? (
        <Skeleton
          animation="wave"
          variant="circular"
          className={classes.loadingIcon}
          width={24}
          height={24}
        />
      ) : (
        <Tooltip
          title={`${isOnBucketlist ? 'Remove from' : 'Add to'} bucketlist`}
          arrow
        >
          <IconButton
            aria-label="Toggle bucketlist"
            onClick={handleBucketlistToggle}
            size="large"
          >
            <PlaylistAddIcon color={isOnBucketlist ? 'secondary' : 'default'} />
          </IconButton>
        </Tooltip>
      )}
      <Snackbar
        open={isSuccessOpen}
        autoHideDuration={10000}
        onClose={handleSuccessClose}
      >
        <Alert onClose={handleSuccessClose} severity="success">
          <strong>{stadium?.name}</strong> {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={isErrorOpen}
        autoHideDuration={10000}
        onClose={handleErrorClose}
      >
        <Alert onClose={handleErrorClose} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

BucketlistsIcon.defaultProps = {
  stadium: {},
  venueId: null,
};

BucketlistsIcon.propTypes = {
  stadium: stadiumType,
  venueId: PropTypes.string,
};
