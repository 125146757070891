import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { stadiumType } from '@helpers/types';
// npm imports
import { Loader } from '@googlemaps/js-api-loader';
import { useNavigate } from 'react-router-dom';
// mui imports
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Card from '@mui/material/Card';
import Skeleton from '@mui/material/Skeleton';
// local imports
import mapPin from '@assets/images/Map/map-pin.png';

function Map({ stadiums, type }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme?.breakpoints?.down('md'));
  const navigate = useNavigate();

  const [isMapLoading, setIsMapLoading] = useState(true);

  useEffect(() => {
    if (stadiums) {
      const loader = new Loader({
        apiKey: process.env.REACT_APP_GOOGLE_MAPS_JS_API_KEY,
        version: 'weekly',
        libraries: ['places'],
      });

      // center map over United States by default
      // mobile and desktop are a bit different due to map size
      let mapLatLon = {
        lat: isMobile ? parseFloat(41.424248) : parseFloat(39.424248),
        lng: isMobile ? parseFloat(-97.325562) : parseFloat(-93.325562),
      };

      // if single stadium, center map on stadium
      if (type === 'single') {
        mapLatLon = {
          lat: parseFloat(stadiums[0]?.location?.latitude),
          lng: parseFloat(stadiums[0]?.location?.longitude),
        };
      }

      const mapOptions = {
        center: mapLatLon,
        zoom: type === 'single' ? 13 : isMobile ? 3 : 4,
        mapId: '5abf7ca9fe49ff34',
      };

      loader
        .load()
        .then((google, navigate) => {
          const map = new google.maps.Map(
            document.getElementById('stadiumMap'),
            mapOptions
          );

          // Create an info window to share between markers.
          const infoWindow = new google.maps.InfoWindow();

          // Create the markers for each stadiums.
          stadiums.forEach((stadium, navigate, i) => {
            const mapLatLon = {
              lat: parseFloat(stadium?.location.latitude),
              lng: parseFloat(stadium?.location.longitude),
            };

            const marker = new google.maps.Marker({
              icon: mapPin,
              position: mapLatLon,
              map,
              title: stadium?.name,
              optimized: true,
            });

            // TODO: update infoBoxContent
            // TODO: make link react-router
            const infoBoxContent = `
              <div id="content">
                <h3 style="line-height: 1em; margin-bottom: 0px;"><a href="/stadium/${stadium?.slug}" id="stadiumLink" style="color: #1c1c1c; text-decoration: none;">${stadium?.name}</a></h3>
                <p style="line-height: 1em; margin-top: 6px;">${stadium?.location.city}, ${stadium?.location.region}</p>
              </div>
            `;

            // add click listener to open infoWindow on multiple stadium views
            if (type === 'multiple') {
              // Add a click listener for each marker, and set up the info window.
              marker.addListener('click', () => {
                infoWindow.close();
                infoWindow.setContent(infoBoxContent);
                infoWindow.open({
                  anchor: marker,
                  map: marker.getMap(),
                  shouldFocus: false,
                });
              });
            }

            // add onCLick to stadium link
            const stadiumLink = document.getElementById('stadiumLink');
            if (stadiumLink) {
              stadiumLink.addListener('click', () => {
                alert('This is the link');
              });
            }
          });
        })
        .then(() => setIsMapLoading(false))
        .catch((e) => {
          // TODO (error-handling): add better error handling, possibly including a logging service
          console.log('There was an error loading your map.', e);
        });
    }
  }, [
    isMapLoading,
    isMobile,
    navigate,
    stadiums,
    theme.palette.primary.main,
    type,
  ]);

  return (
    <Card>
      <div
        style={{
          height: type === 'single' ? '300px' : isMobile ? '300px' : '666px',
        }}
      >
        {isMapLoading && (
          <Skeleton animation="wave" width="100%" height="100%" />
        )}
        <div
          id="stadiumMap"
          style={{
            height: type === 'single' ? '300px' : isMobile ? '300px' : '100%',
          }}
        ></div>
      </div>
    </Card>
  );
}

Map.defaultProps = {
  type: PropTypes.PropTypes.oneOf(['single', 'multiple']).isRequired,
  stadiums: null,
};

Map.propTypes = {
  type: PropTypes.PropTypes.oneOf(['single', 'multiple']).isRequired,
  stadiums: PropTypes.arrayOf(stadiumType),
};

export default Map;
