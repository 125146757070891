import callAPI from './callAPI';

/**
 * @name getStadiums
 * @description get a list of teams
 * @param {string} league league to filter by
 * @returns {object} response object
 */
export const getTeams = ({ league }) => {
  return callAPI({
    url: '/teams/', // TODO: remove trailing slash once that's figure out
    params: { league },
  });
};
