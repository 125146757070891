import callAPI from './callAPI';

/**
 * @name getVisits
 * @description get list of visits to a single stadium by a single user
 * @param {string} accountId ID of the account
 * @param {string} venueId ID of the venue
 * @returns {object} response object
 */
export const getVisits = ({ accountId, venueId }) => {
  return callAPI({
    url: '/visits/', // TODO: remove trailing slash once that's figure out
    params: { accountId, venueId },
  });
};
