import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// npm imports
import clsx from 'clsx';
import { format } from 'date-fns';
import pluralize from 'pluralize';
// mui imports
// import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { grey } from '@mui/material/colors';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';

// npm imports
import { formatInTimeZone } from 'date-fns-tz';
import { useParams } from 'react-router-dom';
// mui imports

import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
// import Item from '@mui/material/Item';
import LinearProgress from '@mui/material/LinearProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
// local imports
import NotFound from '@pages/NotFound';
import { useAuth } from '@providers/AuthProvider';
import { Flags } from '@providers/FlagsProvider';
import { getProfile } from 'client/api';
import { jsifyDate } from '@helpers/functions/jsifyDate';

import Link from '@components/shared/Link';

import { useLocation, useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .visitsTable.unknownData': {
      fontStyle: 'italic',
      color: grey[500],
    },
  },
}));

function BucketlistTable({ bucketlist, bucketlistCount }) {
  const classes = useStyles();

  const columns = [
    {
      field: 'venue',
      headerName: 'Venue',
      width: 275,
      editable: true,
      renderCell: (params) => (
        <Link to={`/stadium/${params.value.venueSlug}`}>
          {params.value.venueName}
        </Link>
      ),
      cellClassName: (params, classes) =>
        clsx('visitsTable visitsTableSeats', {
          unknownData: params.value === 'Unknown',
        }),
    },
    {
      field: 'date',
      headerName: 'Added on',
      editable: true,
      minWidth: 175,
      maxWidth: 175,
      width: 175,
      valueFormatter: (params) =>
        params.value !== 'Unknown'
          ? format(jsifyDate(params.value), 'MMMM d, yyyy')
          : params.value,
      cellClassName: (params) =>
        clsx('visitsTable visitsTableDate', {
          unknownData: params.value === 'Unknown',
        }),
    },
  ];

  const rows = bucketlist.map((bucket) => {
    return {
      id: bucket.id,
      date: bucket.added,
      venue: {
        venueName: bucket.venueName,
        venueSlug: bucket.venueSlug,
      },
    };
  });

  console.log('rows', rows);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <DataGrid
          autoHeight
          className={classes.root}
          density="compact"
          rows={rows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[5, 10, 25]}
          // checkboxSelection
          // disableSelectionOnClick
        />
      </Grid>
    </Grid>
  );
}

BucketlistTable.defaultProps = {
  bucketlist: [],
  bucketlistCount: 0,
};

BucketlistTable.propTypes = {
  bucketlist: PropTypes.shape({
    name: PropTypes.string,
  }),
  bucketlistCount: PropTypes.number,
};

function Bucketlist() {
  const { account } = useAuth();
  // const { profileSlug } = useParams();
  const classes = useStyles();
  // const isMobile = useMediaQuery((theme) => theme.breakpoints?.down('md'));
  const navigate = useNavigate();

  // component state
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [profile, setProfile] = useState(null);

  // convert date to JS version - TODO: convert date helper

  useEffect(() => {
    if (account) {
      getProfile({
        accountId: account?.id,
      })
        .then((response) => {
          setProfile(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setError(error);
          setIsLoading(false);
        });
    }
  }, [account]);

  if (error) {
    // TODO: better not found message
    return (
      <NotFound title="Not Found" subtitle="Profile could not be found." />
    );
  }

  return (
    <Container max="lg" disableGutters>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography component="h1" variant="h3" align="left">
            {isLoading ? (
              <Skeleton animation="wave" width="25%" />
            ) : (
              `${profile?.firstName}'s Favorites`
            )}
          </Typography>
        </Grid>
        {/* <Flags authorizedFlags={['accountActions']}>
          <List component={Stack} direction="row">
            <ListItem>Visits</ListItem>
            <ListItem>Favorites</ListItem>
            <ListItem>Bucket List</ListItem>
          </List>
        </Flags> */}
        <Grid item xs={12}>
          <BucketlistTable
            profile={profile}
            bucketlist={profile?.favorites}
            bucketlistCount={profile?.favorites.length}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default Bucketlist;
