import callAPI from './callAPI';

/**
 * @name addVisit
 * @description add a new visit to a single stadium by a single user
 * @param {string} accountId ID of the user account
 * @param {string} venueId ID of the venue
 * @param {string} [event] event attended
 * @param {string} [date] date of event
 * @param {string} [section] section of seat
 * @param {string} [row] row of seat
 * @param {string} [seat] number of seat
 * @returns {object} response object
 */
export const addVisit = ({
  accountId,
  venueId,
  event,
  date,
  section,
  row,
  seat,
}) => {
  return callAPI({
    url: '/visits/', // TODO: remove trailing slash once that's figure out
    method: 'post',
    data: {
      accountId,
      venueId,
      event,
      date,
      section,
      row,
      seat,
    },
  });
};
