import React, { createContext } from 'react';
import PropTypes from 'prop-types';

const FeatureFlags = createContext();

export function Flags({
  authorizedFlags,
  children,
  exactFlags,
  renderOff,
  renderOn,
}) {
  const matchingFlagsFilter = (flags) => {
    return flags.filter((flag) => {
      return flag.isActive && authorizedFlags.includes(flag.name);
    });
  };

  const resolveRender = (matchingFlags) => {
    return children && children ? children : renderOn(matchingFlags);
  };

  return (
    <FeatureFlags.Consumer>
      {(flags) => {
        const matchingFlags = matchingFlagsFilter(flags);
        if (exactFlags) {
          return matchingFlags.length === authorizedFlags.length
            ? resolveRender(matchingFlags)
            : renderOff(matchingFlags);
        } else {
          return matchingFlags.length
            ? resolveRender(matchingFlags)
            : renderOff(matchingFlags);
        }
      }}
    </FeatureFlags.Consumer>
  );
}

Flags.defaultProps = {
  authorizedFlags: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.node.isRequired,
  exactFlags: false,
  renderOn: () => null,
  renderOff: () => null,
};

Flags.propTypes = {
  authorizedFlags: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.node.isRequired,
  exactFlags: PropTypes.bool,
  renderOn: PropTypes.func,
  renderOff: PropTypes.func,
};

export function FlagsProvider({ children, flags }) {
  return (
    <FeatureFlags.Provider value={flags}>{children}</FeatureFlags.Provider>
  );
}

FlagsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  flags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      isActive: PropTypes.bool,
    })
  ).isRequired,
};
