import callAPI from './callAPI';

/**
 * @name getIsOnBucketlist
 * @description get boolean value of bucketlist status of a single stadium for a single user
 * @param {string} accountId ID of the account
 * @param {string} venueId ID of the venue
 * @returns {object} response object
 */
export const getIsOnBucketlist = ({ accountId, venueId }) => {
  return callAPI({
    url: '/accounts/bucketlists/', // TODO: remove trailing slash once that's figure out
    params: { accountId, venueId },
  });
};
