import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// mui imports
import { useTheme } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
// local imports
import { useAuth } from '@providers/AuthProvider';
import { getProfile } from 'client/api';

function Leaderboard({ isLoading, leaderboard, type }) {
  const { account } = useAuth();
  const theme = useTheme();

  const [profile, setProfile] = useState(null);
  const [isProfileLoading, setIsProfileLoading] = useState(true);
  const [profileError, setProfileError] = useState(null);

  useEffect(() => {
    if (account) {
      getProfile({
        accountId: account?.id,
      })
        .then((response) => {
          setProfile(response.data);
          setIsProfileLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setProfileError(error); // TODO: better error handling
          setIsProfileLoading(false);
        });
    }
  }, [account]);

  const isProfileOnLeaderboard =
    profile && leaderboard?.some((l) => l.accountId === profile?.accountId);

  // TODO: better error handling
  if (profileError) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Alert severity="error">
            There was an error loading this leaderboard. Please try again later.
          </Alert>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {isLoading ? (
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <Divider variant="middle" component="li" />
            {[23, 34, 45, 20, 40, 30, 27, 47, 37, 19].map((number) => {
              return (
                <>
                  <ListItem
                    key={number}
                    secondaryAction={
                      <Typography variant="h5" component="p">
                        <Skeleton animation="wave" width="50px" />
                      </Typography>
                    }
                    sx={{ padding: theme.spacing(2) }}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <Skeleton animation="wave" variant="circular" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      id={1}
                      primary={
                        <Skeleton animation="wave" width={`${number}%`} />
                      }
                    >
                      <Skeleton animation="wave" width="100%" />
                    </ListItemText>
                  </ListItem>
                  <Divider variant="middle" component="li" />
                </>
              );
            })}
          </List>
        ) : (
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <ListItem
              key="header"
              secondaryAction={
                <Typography variant="h5" component="p">
                  Visited
                </Typography>
              }
              sx={{
                padding: theme.spacing(2),
                backgroundColor: '#000000',
                color: '#ffffff',
                marginBottom: theme.spacing(2),
              }}
            >
              <ListItemText
                id="listHeader"
                disableTypography
                primary={
                  <>
                    <Typography variant="h5" component="p">
                      Name
                    </Typography>
                  </>
                }
              />
            </ListItem>
            {leaderboard?.map((leader) => {
              const labelId = `checkbox-list-secondary-label-${leader?.username}`;
              return (
                <ListItem
                  key={leader?.id}
                  secondaryAction={
                    <Typography variant="h5" component="p">
                      {leader[type]}
                    </Typography>
                  }
                  sx={{
                    padding: theme.spacing(2),
                    marginBottom: theme.spacing(1),
                    backgroundColor:
                      leader?.accountId === profile?.accountId
                        ? theme.palette.primary.light
                        : theme.palette.divider,
                  }}
                >
                  <ListItemAvatar>
                    <Avatar
                      alt={`Avatar n°${1 + 1}`}
                      src={`/static/images/avatar/${1 + 1}.jpg`}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    id={labelId}
                    disableTypography
                    primary={
                      <>
                        <Typography>
                          {leader?.username}
                          {leader?.accountId === profile?.accountId && (
                            <>
                              <span style={{ marginRight: theme.spacing(2) }} />
                              <Chip color="secondary" label="You" />
                            </>
                          )}
                        </Typography>
                      </>
                    }
                  />
                </ListItem>
              );
            })}
            {!isProfileLoading && profile && !isProfileOnLeaderboard && (
              <>
                <Divider
                  component="li"
                  sx={{
                    marginTop: theme.spacing(4),
                    marginBottom: theme.spacing(4),
                  }}
                />
                <ListItem
                  key={profile?.accountId}
                  secondaryAction={
                    <Typography variant="h5" component="p">
                      {type === 'total'
                        ? profile?.visits?.allVenuesUniqueCount
                        : type === 'current'
                        ? profile?.visits?.currentStadiumVisitsCount
                        : profile?.visits[type]?.currentStadiumVisitsCount}
                    </Typography>
                  }
                  sx={{
                    backgroundColor: theme.palette.primary.light,
                    padding: theme.spacing(2),
                  }}
                >
                  <ListItemAvatar>
                    <Avatar
                      alt={`Avatar n°${1 + 1}`}
                      src={`/static/images/avatar/${1 + 1}.jpg`}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    id={profile?.accountId}
                    disableTypography
                    primary={
                      <>
                        <Typography>
                          {profile?.username}
                          <span style={{ marginRight: theme.spacing(2) }} />
                          <Chip color="secondary" label="You" />
                        </Typography>
                      </>
                    }
                  />
                </ListItem>
              </>
            )}
          </List>
        )}
      </Grid>
    </Grid>
  );
}

Leaderboard.defaultProps = {
  isLoading: false,
  leaderboard: [],
  type: 'current',
};

Leaderboard.propTypes = {
  isLoading: PropTypes.bool,
  leaderboard: PropTypes.shape({
    name: PropTypes.string,
  }),
  type: PropTypes.string,
};

export default Leaderboard;
