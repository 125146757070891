import callAPI from './callAPI';

/**
 * @name deleteFavorite
 * @description remove stadium from favorites of single user
 * @param {string} accountId ID of the account
 * @param {string} venueId ID of the venue
 * @param {string} isFavorite value to set favorite status to
 * @returns {object} response object
 */
export const deleteFavorite = ({ accountId, venueId, isFavorite }) => {
  return callAPI({
    url: '/accounts/favorites/', // TODO: remove trailing slash once that's figure out
    method: 'delete',
    data: {
      accountId,
      venueId,
      isFavorite,
    },
  });
};
