import React from 'react';
// mui imports
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import AccountCircle from '@mui/icons-material/AccountCircle';
// local imports
import { useAuth } from '@providers/AuthProvider';
import Link from '@components/shared/Link';

const useStyles = makeStyles((theme) => ({
  image: {
    backgroundImage: 'url(/images/coors-field-background.jpg)', // TODO: move image to better server
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.mode === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  alreadyAuthorized: {
    padding: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export default function AlreadyAuthorized() {
  const auth = useAuth();
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Grid container className={classes.alreadyAuthorized} spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={1} alignItems="center" direction="column">
              <Grid item xs={12}>
                <Avatar sx={{ bgcolor: theme.palette.success.light }}>
                  <AccountCircle />
                </Avatar>
              </Grid>
              <Grid item xs={12}>
                <Typography align="center" component="h1" variant="h5">
                  You're already signed in, {auth?.account?.firstName}.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography align="center" p>
              If this isn't you, please help out the last user by{' '}
              <Link to="">
                <span onClick={() => auth.signOut()}>signing out for them</span>
              </Link>
              .
            </Typography>
            <Typography align="center" p>
              If you'd like to change your information,{' '}
              <Link to="/profile">head to your profile page</Link>.
            </Typography>
            <Typography align="center" p>
              <Link to="/">Head to the homepage</Link> if you landed here by
              mistake.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
