import React, { Fragment } from 'react';
// mui imports
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// local imports
import Hero from '@components/shared/Hero';
import Link from '@components/shared/Link';
import PageTitle from '@components/shared/PageTitle';

function About() {
  return (
    <Fragment>
      <PageTitle title="About" />
      <Hero title="About / FAQ" />
      <Container max="lg" disableGutters>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography component="p" variant="h6" paragraph>
              Stadiums.me is the best place to track which Big Four (MLB, NBA,
              NFL and NHL) stadiums you've visited. Compete with your friends to
              see who's visited more venues, or just keep a list for yourself.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Accordion TransitionProps={{ unmountOnExit: true }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  Which stadiums are featured on the site?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography paragraph>
                  Our goal is to feature every stadium a team has used for any
                  event where they are designated as the home team, for all
                  current MLB, NBA, NFL, and NHL franchises, including their
                  predecessors.
                </Typography>
                <Typography paragraph>
                  For example, the Arizona Cardinals will have their current
                  home,{' '}
                  <Link to="/stadium/state-farm-stadium">
                    State Farm Stadium
                  </Link>
                  , as well as their previous permanent homes in St. Louis (as
                  the St. Louis Cardinals) and Chicago (as the Chicago
                  Cardinals). They've also played games at{' '}
                  <Link to="/stadium/memorial-stadium-baltimore">
                    Memorial Stadium
                  </Link>{' '}
                  in Baltimore,{' '}
                  <Link to="/stadium/metropolitan-stadium">
                    Metropolitan Stadium
                  </Link>{' '}
                  in Bloomington, and{' '}
                  <Link to="/stadium/wisconsin-state-fair-park">
                    Wisconsin State Fair Park
                  </Link>{' '}
                  in Milwaukee for special events or when there have been
                  conflicts at their home stadium.
                </Typography>
                <Typography paragraph>
                  We currently feature all current and historical permanent
                  stadiums for all current MLB, NBA, NFL, and NHL franchises,
                  including their predecessors.
                </Typography>

                <Typography paragraph>
                  Stadiums which served as the venue for special event type
                  games, such as the NHL Stadium Series, MLB in Omaha series, or
                  NFL International Series, are not yet available, however we
                  are in the process of adding the following:
                </Typography>
                <List dense={true}>
                  <ListItem>
                    <ListItemIcon>
                      <img
                        alt="mlb logo"
                        src="https://i.logocdn.com/mlb/current/mlb.svg"
                        width="30"
                        height="30"
                      />
                    </ListItemIcon>
                    <ListItemText primary="MLB All-Star Games" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <img
                        alt="mlb logo"
                        src="https://i.logocdn.com/mlb/current/mlb.svg"
                        width="30"
                        height="30"
                      />
                    </ListItemIcon>
                    <ListItemText primary="MLB International Games" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <img
                        alt="mlb logo"
                        src="https://i.logocdn.com/mlb/current/mlb.svg"
                        width="30"
                        height="30"
                      />
                    </ListItemIcon>
                    <ListItemText primary="MLB Special Event Games, such as MLB in Omaha" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <img
                        alt="nba logo"
                        src="https://i.logocdn.com/nba/current/nba.svg"
                        width="30"
                        height="30"
                      />
                    </ListItemIcon>
                    <ListItemText primary="NBA All-Star Games" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <img
                        alt="nba logo"
                        src="https://i.logocdn.com/nba/current/nba.svg"
                        width="30"
                        height="30"
                      />
                    </ListItemIcon>
                    <ListItemText primary="NBA International Games" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <img
                        alt="nfl logo"
                        src="https://i.logocdn.com/nfl/current/nfl.svg"
                        width="30"
                        height="30"
                      />
                    </ListItemIcon>
                    <ListItemText primary="NFL International Games" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <img
                        alt="nfl logo"
                        src="https://i.logocdn.com/nfl/current/nfl.svg"
                        width="30"
                        height="30"
                      />
                    </ListItemIcon>
                    <ListItemText primary="NFL Pro-Bowls" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <img
                        alt="nfl logo"
                        src="https://i.logocdn.com/nfl/current/nfl.svg"
                        width="30"
                        height="30"
                      />
                    </ListItemIcon>
                    <ListItemText primary="NFL Super Bowls" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <img
                        alt="nhl logo"
                        src="https://i.logocdn.com/nhl/current/nhl.svg"
                        width="30"
                        height="30"
                      />
                    </ListItemIcon>
                    <ListItemText primary="NHL All-Star Games" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <img
                        alt="nhl logo"
                        src="https://i.logocdn.com/nhl/current/nhl.svg"
                        width="30"
                        height="30"
                      />
                    </ListItemIcon>
                    <ListItemText primary="NHL International Games" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <img
                        alt="nhl logo"
                        src="https://i.logocdn.com/nhl/current/nhl.svg"
                        width="30"
                        height="30"
                      />
                    </ListItemIcon>
                    <ListItemText primary="NHL Stadium Series" />
                  </ListItem>
                </List>
              </AccordionDetails>
            </Accordion>
            <Accordion TransitionProps={{ unmountOnExit: true }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>Why only those leagues?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography paragraph>
                  Stadiums.me is a United States based site, and these are the
                  traditional "Big Four" leagues in the US. We'd love to expand
                  to other countries and leagues in the future, time permitting.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion TransitionProps={{ unmountOnExit: true }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3a-content"
                id="panel3a-header"
              >
                <Typography>
                  Why do you list the current franchise name, even if franchise
                  has long moved on?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography paragraph>
                  Most people are probably more familiar with the current
                  franchise name than they are historical names. If the Quebec
                  Nordiques were listed first, many people might not dig deeper
                  and realize this is the same franchise as the current Colorado
                  Avalanche. By displaying the current franchise name first, it
                  encourages you to dig deeper into a teams history.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion TransitionProps={{ unmountOnExit: true }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4a-content"
                id="panel4a-header"
              >
                <Typography>Where does stadium data come from?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography paragraph>
                  Stadium data is gathered through various sites and resources,
                  including news articles, team websites, and Wikipedia.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion TransitionProps={{ unmountOnExit: true }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel5b-content"
                id="panel5b-header"
              >
                <Typography>
                  Your data is outdated, do you refresh the information
                  regularly?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography paragraph>
                  We audit all current stadiums for each league prior to the
                  beginning of a new league year. For example, we'll update all
                  current MLB venues with new names, events, etc, in March
                  before the league year begins in April.
                </Typography>
                <Typography paragraph>
                  Data for stadiums which are no longer in use by a professional
                  team may become out of date, due to naming sponsor changes or
                  closure / demolition dates.
                </Typography>
                <Typography paragraph>
                  If you think any information might be incorrect or out of
                  date, please don't hesitate to{' '}
                  <Link to="/contact">contact us</Link>.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion TransitionProps={{ unmountOnExit: true }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel6a-content"
                id="panel6a-header"
              >
                <Typography>Why did you make this site?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography paragraph>
                  Stadiums.me was originally built as a way for the founder and
                  his father to keep track of which stadiums they had visited.
                  Although there are many other sites out there, any we came
                  across focused on a single sport. We are huge fans of all
                  sports, so we figured let's make our own! Slowly, friends and
                  family kept mentioning they want to be part of the fun too, so
                  we opened it up to the public!
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
}

export default About;
