import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { stadiumType } from '@helpers/types';
// mui imports
import makeStyles from '@mui/styles/makeStyles';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Skeleton from '@mui/material/Skeleton';
import Snackbar from '@mui/material/Snackbar';
import FavoriteIcon from '@mui/icons-material/Favorite';
// local imports
import { useAuth } from '@providers/AuthProvider';
import { addFavorite, getIsFavorite, deleteFavorite } from 'client/api';

// TODO: replace makeStyles
const useStyles = makeStyles((theme) => ({
  loadingIcon: {
    margin: '12px',
  },
}));

export default function FavoritesIcon({ stadium, venueId }) {
  const { account, isLoading: isAuthLoading } = useAuth();
  const classes = useStyles();

  const [isFavorite, setIsFavorite] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // after success
  // opened / closed state for success toast
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  // handles success toast closing
  const handleSuccessClose = () => {
    setIsSuccessOpen(false);
  };

  // after error
  // opened / closed state for error toast
  const [isErrorOpen, setIsErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  // handles error toast closing
  const handleErrorClose = () => {
    setIsErrorOpen(false);
  };

  const handleFavoriteToggle = () => {
    if (isFavorite) {
      deleteFavorite({
        accountId: account.id,
        venueId,
        isFavorite: !isFavorite,
      })
        .then((response) => {
          setIsLoading(false);
          setIsFavorite(!isFavorite);
          setSuccessMessage('removed from favorites');
          setIsSuccessOpen(true);
        })
        .catch((error) => {
          setIsLoading(false);
          console.error(error); // TODO: error messaging
          setErrorMessage(
            'Something went wrong, and your favorite was not removed. Please refresh and try again.'
          );
          setIsErrorOpen(true);
        });
    } else {
      addFavorite({
        accountId: account.id,
        venueId,
        isFavorite: !isFavorite,
      })
        .then((response) => {
          setIsLoading(false);
          setIsFavorite(!isFavorite);
          setSuccessMessage('saved as a favorite');
          setIsSuccessOpen(true);
        })
        .catch((error) => {
          setIsLoading(false);
          console.error(error); // TODO: error messaging
          // error 400: not all fields provided or bad data
          setErrorMessage(
            'Something went wrong, and your favorite was not saved. Please refresh and try again.'
          );
          setIsErrorOpen(true);
        });
    }
  };

  useEffect(() => {
    if (account && venueId) {
      getIsFavorite({
        accountId: account.id,
        venueId,
      })
        .then((response) => {
          setIsFavorite(response.data.isFavorite);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error); // TODO: error messaging
          setIsLoading(false);
        });
    }
  }, [account, venueId]);

  // check all loading status together to make sure everything is ready
  const isAnyLoading = isLoading || isAuthLoading;
  return (
    <>
      {isAnyLoading ? (
        <Skeleton
          animation="wave"
          variant="circular"
          className={classes.loadingIcon}
          width={24}
          height={24}
        />
      ) : (
        <Tooltip
          title={`${isFavorite ? 'Remove from' : 'Add to'} favorites`}
          arrow
        >
          <IconButton
            aria-label="Toggle favorite"
            onClick={handleFavoriteToggle}
            size="large"
          >
            <FavoriteIcon color={isFavorite ? 'secondary' : 'default'} />
          </IconButton>
        </Tooltip>
      )}
      <Snackbar
        open={isSuccessOpen}
        autoHideDuration={10000}
        onClose={handleSuccessClose}
      >
        <Alert onClose={handleSuccessClose} severity="success">
          <strong>{stadium?.name}</strong> {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={isErrorOpen}
        autoHideDuration={10000}
        onClose={handleErrorClose}
      >
        <Alert onClose={handleErrorClose} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

FavoritesIcon.defaultProps = {
  stadium: {},
  venueId: null,
};

FavoritesIcon.propTypes = {
  stadium: stadiumType,
  venueId: PropTypes.string,
};
