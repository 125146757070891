import callAPI from './callAPI';

/**
 * @name getProfile
 * @description get profile information for a single account
 * @param {string} accountId ID of the user account
 * @returns {object} response object
 */
export const getProfile = ({ accountId }) => {
  return callAPI({
    url: '/profile/', // TODO: remove trailing slash once that's figure out
    params: { accountId },
  });
};
