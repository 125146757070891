import React, { Fragment, useEffect, useState } from 'react';
// mui imports
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import SportsBaseballIcon from '@mui/icons-material/SportsBaseball';
import SportsBasketballIcon from '@mui/icons-material/SportsBasketball';
import SportsFootballIcon from '@mui/icons-material/SportsFootball';
import SportsHockeyIcon from '@mui/icons-material/SportsHockey';
// local imports
import { getLeaderboard, getStadiums } from '@api';
import PageTitle from '@components/shared/PageTitle';
import LeaderboardList from '@components/Leaderboard';
import NotFound from '@pages/NotFound';

function Leaderboard() {
  const [leaderboard, setLeaderboard] = useState(null);
  const [isLeaderboardLoading, setIsLeaderboardLoading] = useState(true);
  const [leaderboardError, setLeaderboardError] = useState(null); // TODO (error messaging): add error messaging if leaderboards cannot be found

  const [stadiumsData, setStadiumsData] = useState(null);
  const [isStadiumsLoading, setIsStadiumsLoading] = useState(true);
  const [stadiumsError, setStadiumsError] = useState(false);

  const [tabValue, setTabValue] = useState('current');

  useEffect(() => {
    setIsLeaderboardLoading(true);
    getLeaderboard({ type: tabValue })
      .then((response) => {
        setLeaderboard(response.data);
        setIsLeaderboardLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLeaderboardError(error); // TODO (error messaging): add error messaging if stadiums cannot be found
        setIsLeaderboardLoading(false);
      });
  }, [tabValue]);

  useEffect(() => {
    setIsStadiumsLoading(true);
    getStadiums({
      filter: null,
      league:
        tabValue === 'total'
          ? 'all'
          : tabValue === 'current'
          ? 'all'
          : tabValue,
      status: tabValue === 'total' ? null : 'current',
    })
      .then((response) => {
        setStadiumsData(response.data);
        setIsStadiumsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setStadiumsError(error); // TODO (error messaging): add error messaging if stadiums cannot be found
        setIsStadiumsLoading(false);
      });
  }, [tabValue]);

  if (leaderboardError || stadiumsError) {
    // TODO (not found): better not found message
    return (
      <NotFound
        title="Not Found"
        subtitle="This leaderboard could not be found."
      />
    );
  }

  const handleTabChange = (event, newTabValue) => {
    setTabValue(newTabValue);
  };

  return (
    <Container max="lg" disableGutters>
      <PageTitle title="Leaderboard" />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography component="h1" variant="h3" align="left">
            Leaderboards
          </Typography>
          <Typography
            variant="overline"
            align="left"
            sx={{ textTransform: 'uppercase' }}
          >
            Top 25 Visitors
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TabContext value={tabValue}>
                <Tabs
                  onChange={handleTabChange}
                  aria-label="top visitors"
                  value={tabValue}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                  }}
                >
                  <Tab
                    icon={<LeaderboardIcon />}
                    iconPosition="start"
                    label="Current Stadiums"
                    value="current"
                    sx={{}}
                  />
                  <Tab
                    icon={<LeaderboardIcon />}
                    iconPosition="start"
                    label="All Stadiums"
                    value="total"
                    sx={{}}
                  />
                  <Tab
                    icon={<SportsBaseballIcon />}
                    iconPosition="start"
                    label="MLB"
                    value="mlb"
                    sx={{}}
                  />
                  <Tab
                    icon={<SportsBasketballIcon />}
                    iconPosition="start"
                    label="NBA"
                    value="nba"
                    sx={{}}
                  />
                  <Tab
                    icon={<SportsFootballIcon />}
                    iconPosition="start"
                    label="NFL"
                    value="nfl"
                    sx={{}}
                  />
                  <Tab
                    icon={<SportsHockeyIcon />}
                    iconPosition="start"
                    label="NHL"
                    value="nhl"
                    sx={{}}
                  />
                </Tabs>
                <TabPanel value="current">
                  <Typography paragraph>
                    {isStadiumsLoading ? (
                      <>
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" width="25%" />
                      </>
                    ) : (
                      `There are ${stadiumsData?.stadiumCount} venues currently used
                      as permanent homes to 124 MLB, NBA, NFL, and NHL teams.`
                    )}
                  </Typography>
                </TabPanel>
                <TabPanel value="total">
                  <Typography paragraph>
                    {isStadiumsLoading ? (
                      <>
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" width="25%" />
                      </>
                    ) : (
                      `There are currently ${stadiumsData?.stadiumCount} venues in our database as current and historical homes for all 124 professional teams, with more being added all the time. Many of these venues were demolished before anyone currently alive was even born, so it's literally impossible to get a perfect score here.`
                    )}
                  </Typography>
                </TabPanel>
                <TabPanel value="mlb">
                  <Typography paragraph>
                    {isStadiumsLoading ? (
                      <>
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" width="25%" />
                      </>
                    ) : (
                      stadiumsData?.description
                    )}
                  </Typography>
                </TabPanel>
                <TabPanel value="nba">
                  <Typography paragraph>
                    {isStadiumsLoading ? (
                      <>
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" width="25%" />
                      </>
                    ) : (
                      `${stadiumsData?.description} These shared stadiums will show up
                      in both the NBA and NHL leaderboards.`
                    )}
                  </Typography>
                </TabPanel>
                <TabPanel value="nfl">
                  <Typography paragraph>
                    {isStadiumsLoading ? (
                      <>
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" width="25%" />
                      </>
                    ) : (
                      stadiumsData?.description
                    )}
                  </Typography>
                </TabPanel>
                <TabPanel value="nhl">
                  <Typography paragraph>
                    {isStadiumsLoading ? (
                      <>
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" width="25%" />
                      </>
                    ) : (
                      `${stadiumsData?.description} These shared stadiums will show up
                      in both the NBA and NHL leaderboards.`
                    )}
                  </Typography>
                </TabPanel>
              </TabContext>
            </Grid>
            <LeaderboardList
              isLoading={isLeaderboardLoading}
              leaderboard={leaderboard}
              type={tabValue}
            />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Leaderboard;
